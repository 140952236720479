import React, { useState } from "react";
import UiCardAvatar from "../cards/UiCardAvatar";

import "./UiHeaderProfile.scss";

const UiHeaderProfile = (props) => {
  const [active, setActive] = useState(false);

  return (
    <div className="header-profile">
      <UiCardAvatar
        avatarColor={props.color}
        avatarText={props.user.substr(0, 2).toUpperCase()}
        size={48}
        onClick={() => props.onOpen()}
      />
      {props.active ? (
        <div className="header-dropdown">
          <div
            className="header-dropdown-outside"
            onClick={() => props.onClose()}
          ></div>
          <div className="header-dropdown-inner">
            <div className="header-dropdown-profile" onClick={() => props.userClick()}>
              <p>
                {" "}
                <span>Мой профиль</span>
              </p>
              <p>
                {" "}
                <span>{props.user}</span>
              </p>
            </div>
            <button
              className="header-profile-exit"
              onClick={() => props.onLogout()}
            >
              Выйти
            </button>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default UiHeaderProfile;
