import React, { useEffect, useRef, useState } from "react";

import { ReactComponent as SvgAngle } from "../../assets/images/ui/arrow-sort.svg";
import { ReactComponent as SvgCheck } from "../../assets/images/ui/check2.svg";
import { ReactComponent as SvgDelete } from "../../assets/images/ui/delete.svg";
import { ReactComponent as SvgDelivery } from "../../assets/images/ui/scooter.svg";
import { ReactComponent as SvgEye } from "../../assets/images/ui/eye.svg";
import { ReactComponent as SvgEyeOpen } from "../../assets/images/ui/eye-open.svg";
import { ReactComponent as SvgEdit } from "../../assets/images/ui/pencil.svg";
import { ReactComponent as SvgOpen } from "../../assets/images/ui/search.svg";
import { ReactComponent as SvgWait } from "../../assets/images/ui/wait.svg";
import { ReactComponent as SvgWallet } from "../../assets/images/ui/wallet.svg";
import { ReactComponent as SvgArrowDown } from "../../assets/images/ui/arrow-select.svg";

import Env from "../../services/Env";

import "./UiTableAdaptiveCard.scss";

const UiSelectDropdown = (props) => {
  /**
   * clicks outside of ref
   */
  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * if clicked on outside
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          props.onCloseClick();
          //alert("You clicked outside of ref!");
        }
      }
      // bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  return (
    <div className="status-dropdown">
      {props.button ? (
        <button className="status-button" onClick={() => props.onClick()}>
          <span
            className={
              (props.color === "blue" ? " blue" : "") +
              (props.color === "gray" ? " gray" : "") +
              (props.color === "green" ? " green" : "") +
              (props.color === "orange" ? " orange" : "") +
              (props.color === "red" ? " red" : "") +
              (props.color === "cyan" ? " cyan" : "") +
              (props.color === "violet" ? " violet" : "") +
              (props.color === "sky" ? " sky" : "")
            }
          >
            {props.button}
          </span>
        </button>
      ) : null}
      {props.payStatus === "done" ? (
        <SvgCheck onClick={() => props.onClick()} />
      ) : props.payStatus === "wait" ? (
        <SvgWait onClick={() => props.onClick()} />
      ) : null}
      {props.shipmentStatus ? (
        <button className="status-button" onClick={() => props.onClick()}>
          <span className={" violet"}>{props.shipmentStatus}</span>
        </button>
      ) : null}

      {props.selectOpen ? (
        <div className="status-dropdown-list">
          <div /*ref={wrapperRef}*/ className="status-dropdown-list-wrap">
            {props.list}
          </div>
        </div>
      ) : null}
    </div>
  );
};

const UiInputCard = (props) => {
  const [state, setState] = useState(props.input);
  useEffect(() => {
    setState(props.input);
  }, [props.input]);
  return (
    <input
      className={props.className}
      style={{
        width: "40px",
        borderRadius: "5px",
        padding: "2px",
        border: "1px solid #909090",
      }}
      value={state}
      type={"number"}
      onBlur={() => {
        props.onBlurInput(state);
      }}
      onChange={(e) => {
        setState(e.target.value);
      }}
    />
  );
};

const UiTableAdaptiveCard = (props) => {
  const [last, setLast] = useState(null);
  const [statusListOpen, setStatusListOpen] = useState(false);
  const [statusPayListOpen, setStatusPayListOpen] = useState(false);
  const [curListOpen, setCurListOpen] = useState(false);
  const [shipmentListOpen, setShipmentListOpen] = useState(false);

  if (props.cols) {
    if (props.cols.length > 0) {
      var cols = props.cols.map((item, index) => {
        if (item.statusList) {
          var statusList = item.statusList.map((item2, index2) => {
            return (
              <span
                key={index2}
                onClick={() => {
                  setStatusListOpen(false);
                  item.onStatusChange(index2);
                }}
              >
                {item2}
              </span>
            );
          });
        }

        if (item.statusPayList) {
          var statusPayList = item.statusPayList.map((item2, index2) => {
            return (
              <span
                key={index2}
                onClick={() => {
                  setStatusPayListOpen(false);
                  item.onStatusPayChange(index2);
                }}
              >
                {item2}
              </span>
            );
          });
        }

        if (item.curList) {
          var curList = item.curList.map((item2, index2) => {
            return (
              <span
                key={index2}
                onClick={() => {
                  setCurListOpen(false);
                  item.onCurChange(item2.value);
                }}
              >
                {item2.text}
              </span>
            );
          });
        }

        if (item.shipmentList) {
          var shipmentList = item.shipmentList.map((item2, index2) => {
            return (
              <span
                key={index2}
                onClick={() => {
                  setShipmentListOpen(false);
                  item.onShipmentChange(index2 + 1);
                }}
              >
                {item2}
              </span>
            );
          });
        }

        return (
          <td
            className={
              "table-card-col" +
              (item.color === "blue" ? " blue" : "") +
              (item.color === "red" ? " red" : "") +
              (item.noWrap ? " no-wrap" : "") +
              (item.strong ? " strong" : "")
            }
            key={index}
            onClick={item.onClick ? item.onClick : null}
          >
            <div
              className="table-card-col-inner"
              onClick={() => {
                if (item.sort) {
                  props.onFilter(last == index ? `-${index}` : index);
                  setLast(last == index ? null : index);
                }
              }}
            >
              {item.image ? (
                <img
                  src={/*Env.PUBLIC_URL + */ item.image}
                  style={{
                    width: "100px",
                    height: "100px",
                    borderRadius: "4px",
                  }}
                />
              ) : null}
              {item.input ? (
                <UiInputCard
                  input={item.input}
                  onBlurInput={(val) => {
                    item.onBlurInput(val);
                  }}
                />
              ) : null}
              {item.inputSort ? (
                <div className="table-card-sorting">
                  <button
                    className={"table-card-sorting-button"}
                    onClick={() => item.onBlurInput(0)}
                  >
                    <SvgArrowDown className="table-card-sorting-button-icon up" />
                  </button>
                  <p>{`${item.inputSort}`}</p>

                  <button
                    className={"table-card-sorting-button"}
                    onClick={() => item.onBlurInput(1)}
                  >
                    <SvgArrowDown className="table-card-sorting-button-icon" />
                  </button>
                </div>
              ) : null}
              {item.button ? (
                <button
                  className={
                    "table-card-button" +
                    (item.color === "blue" ? " blue" : "") +
                    (item.color === "gray" ? " gray" : "") +
                    (item.color === "green" ? " green" : "") +
                    (item.color === "orange" ? " orange" : "") +
                    (item.color === "red" ? " red" : "") +
                    (item.color === "cyan" ? " cyan" : "") +
                    (item.color === "teal" ? " teal" : "") +
                    (item.color === "violet" ? " violet" : "") +
                    (item.color === "sky" ? " sky" : "")
                  }
                  onClick={item.openClick ? item.openClick : null}
                >
                  {item.button === "edit" ? (
                    item.editText ? (
                      <span>{item.editText}</span>
                    ) : (
                      <SvgEdit className="table-card-button-icon" />
                    )
                  ) : null}
                  {item.button === "open" ? (
                    <SvgOpen className="table-card-button-icon" />
                  ) : null}
                  {item.button === "delete" ? (
                    <SvgDelete className="table-card-button-icon" />
                  ) : null}
                  {item.button === "eye" ? (
                    <SvgEye className="table-card-button-icon" />
                  ) : null}
                  {item.button === "eye-open" ? (
                    <SvgEyeOpen className="table-card-button-icon" />
                  ) : null}
                </button>
              ) : null}

              {props.titles ? (
                <p
                  className={"table-card-title"} 
                  onClick={props.textLink ? item.linkClick : null}
                >
                  {props.titles[index].title}
                </p>
              ) : null}
              {item.sort ? <SvgAngle className="table-card-sort" /> : null}
            </div>
            {item.icon ? (
              item.payStatus === "done" ? (
                <SvgCheck />
              ) : item.payStatus === "wait" ? (
                <SvgWait />
              ) : item.icon === "wallet" ? (
                <SvgWallet />
              ) : item.icon === "delivery" ? (
                <SvgDelivery
                  style={{ position: "relative", bottom: -8, marginRight: 12 }}
                />
              ) : null
            ) : null}
            {item.text ? (
              <p
                className={
                  "table-card-text" +
                  (item.textLink ? " link" : "") +
                  (props.numOfLines ? " nl-fix nl" + props.numOfLines : "")
                } 
                onClick={item.linkClick}
                style={
                  props.numOfLines ? { maxHeight: props.numOfLines * 17 } : null,
                  item.linkClick ? { cursor: "pointer" } : null
                }
              >
                {item.text === "" ? "-" : item.text}
              </p>
            ) : null}
            {item.statusInfo ? (
              <UiSelectDropdown
                color={item.statusColor}
                button={item.statusInfo}
                selectOpen={statusListOpen}
                list={statusList}
                onClick={() => setStatusListOpen(!statusListOpen)}
                onCloseClick={() => setStatusListOpen(false)}
              />
            ) : null}
            {item.curInfo ? (
              <UiSelectDropdown
                color={item.statusColor}
                button={item.curInfo}
                selectOpen={curListOpen}
                list={curList}
                onClick={() => setCurListOpen(!curListOpen)}
                onCloseClick={() => setCurListOpen(false)}
              />
            ) : null}
            {item.statusPayList ? (
              <UiSelectDropdown
                color={item.statusColor}
                payStatus={item.payStatus}
                selectOpen={statusPayListOpen}
                list={statusPayList}
                onClick={() => setStatusPayListOpen(!statusPayListOpen)}
                onCloseClick={() => setStatusPayListOpen(false)}
              />
            ) : null}
            {item.shipmentStatus ? (
              <UiSelectDropdown
                color={item.statusColor}
                shipmentStatus={item.shipmentStatus}
                selectOpen={shipmentListOpen}
                list={shipmentList}
                onClick={() => setShipmentListOpen(!shipmentListOpen)}
                onCloseClick={() => setShipmentListOpen(false)}
              />
            ) : null}
          </td>
        );
      });
    }
  }

  return (
    <tr
      className={
        "table-card" +
        (!props.adaptive ? " static" : "") +
        (props.isTitle ? " title" : "") +
        (props.grid ? " grid-" + props.grid : "") +
        (props.colored ? " colored" : "") +
        (props.color === "blue"
          ? " blue"
          : props.color === "green"
          ? " green"
          : props.color === "yellow"
          ? " yellow"
          : props.color === "white"
          ? " white"
          : props.color === "violet"
          ? " violet"
          : "")
      }
    >
      {props.cols ? cols : (<td><p>-</p></td>)}
    </tr>
  );
};

export default UiTableAdaptiveCard;
