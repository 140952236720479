import ENV from './Env.js';
import ApiConstant from '../constants/ApiConstan.js';

 
export const addOrderSpecialPromo = (_data) => {
    return fetch(`${ENV.API_URL}/promo/store`, {
        method: 'POST',
        headers: ENV.getHeaders(),
        body: JSON.stringify(_data)
    }).then(function (response) {
        return response.json();
    });
}

export const destroyOrderSpecialPromo = (_data) => {
    return fetch(`${ENV.API_URL}/promo/destroy/${_data.id}`, {
        method: 'POST',
        headers: ENV.getHeaders(),
        body: JSON.stringify(_data)
    }).then(function (response) {
        return response.json();
    });
}


export const getOrderPromo = (_data) => {
    return fetch(`${ENV.API_URL}/promo/show/${_data.id}`, {
        method: 'GET',
        headers: ENV.getHeaders(), 
    }).then(function (response) {
        return response.json();
    });

}


export const getAllPromo = () => {
    return fetch(`${ENV.API_URL}/promo/all`, {
        method: 'GET',
        headers: ENV.getHeaders(), 
    }).then(function (response) {
        return response.json();
    });

}


 
export const editOrderSpecialPromo = (_data) => {
    return fetch(`${ENV.API_URL}/promo/update/${_data.id}`, {
        method: 'POST',
        headers: ENV.getHeaders(),
        body: JSON.stringify(_data)
    }).then(function (response) {
        return response.json();
    });
}


