import ENV from './Env.js';



export const getListByCat = (_id) => {
  return fetch(`${ENV.API_URL}/storage/category/${_id}`, {
    method: 'GET',
    headers: ENV.getHeaders(),
  }).then((response) => {
    return response.json()
  });
}

export const getList = (_id, _coord, _type) => {
  let _url = `${ENV.API_URL}/storage/all?user_id=${_id}`;
  if(_coord) _url+=_url+'&coord='+_coord;
  if(_type) _url+=_url+'&type='+_type;

  return fetch(_url, {
    method: 'GET',
    headers: ENV.getHeaders(),
  }).then((response) => {
    return response.json()
  });
}


export const searchStorageByTitle = (_title) => {
  return fetch(`${ENV.API_URL}/storage/search/${_title}`, {
    method: 'GET',
    headers: ENV.getHeaders(),
  }).then((response) => {
    return response.json()
  });
}

export const getListByArray = ( _data) => { 
  return fetch(`${ENV.API_URL}/storage/show/array`, {
    method: 'POST',
    headers: ENV.getHeaders(),
    body: JSON.stringify(_data)
  }).then(function (response) {
    return response.json();
  });
}


export const makeStorage = (_api, _data) => {
  _data['api_token'] = _api;

  return fetch(`${ENV.API_URL}/storage/store`, {
    method: 'POST',
    headers: ENV.getHeaders(),
    body: JSON.stringify(_data)
  }).then(function (response) {
    return response.json();
  });
}


export const updateStorage = (_api, _data) => {
  _data['api_token'] = _api;

  return fetch(`${ENV.API_URL}/storage/update/${_data['id']}`, {
    method: 'POST',
    headers: ENV.getHeaders(),
    body: JSON.stringify(_data)
  }).then(function (response) {
    return response.json();
  });
}

export const getStoragePromo = (_data) => {
  return fetch(`${ENV.API_URL}/promo/storage/show/${_data.id}`, {
      method: 'GET',
      headers: ENV.getHeaders(), 
  }).then(function (response) {
      return response.json();
  });

}

export const getAllStoragePromo = () => {
  return fetch(`${ENV.API_URL}/promo/all`, {
      method: 'GET',
      headers: ENV.getHeaders(), 
  }).then(function (response) {
      return response.json();
  });

}


export const getCompositionList = () => {
  return fetch(`${ENV.API_URL}/composition/list/show`, {
      method: 'GET',
      headers: ENV.getHeaders(), 
  }).then(function (response) {
      return response.json();
  });

}