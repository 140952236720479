import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { addFile, retrieveData, safelyParseJSON } from "../../services/Storage";
import { useNavigate, useParams } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import UiInputText from "../../components/inputs/UiInputText";
import UiTextInputMasked from "../../components/inputs/UiTextInputMasked";
import UiModalImageCrop from "../../components/modals/UiModalImageCrop";
import UiMapAddress from "../../components/map/UiMapAddress";
import UiSelect from "../../components/select/UiSelect";
import UiSelectDates from "../../components/select/UiSelectDates";
import UiDropDown from "../../components/inputs/UiDropDown";
import UiTextArea from "../../components/inputs/UiTextArea";
import UiCardAddPhoto from "../../components/cards/UiCardAddPhoto";
import UiButtonColor from "../../components/button/UiButtonColor";
import UiTableSmall from "../../components/table/UiTableSmall";
import UiModalEditComposition from "../../components/modals/UiModalEditComposition";

import StorageConstant from "../../constants/StorageConstant";
import OrderConstant from "../../constants/OrderConstant";

import {
  addSpecialPromo as addSpecialPromoAction,
  destroySpecialPromo as destroySpecialPromoAction,
  editSpecialPromo as editSpecialPromoAction,
} from "../../redux/modules/promo";
import {
  getCategoryList as getCategoryListAction,
  getSubCategoryList as getSubCategoryListAction,
} from "../../redux/modules/category";
import {
  getPromoByStorage as getPromoByStorageAction,
  addStorage as addStorageAction,
  update as updateAction,
} from "../../redux/modules/storage";

import Env from "../../services/Env";
import { addFileBase64, convertImages } from "../../services/Storage";
import getCroppedImg from "../../services/CropImage";

import "./StoragesStyle.scss";

import {
  toArrayListKey,
  toArrayImagesListKey,
  fromArrayListKey,
  inArrayValueByKey,
  inArray,
  inArrayValue,
  unCheckInArray,
} from "../../utilities/array";
import { formatDateDMY } from "../../utilities/date";

function StoragesAddPage(props) {
  const navigate = useNavigate();

  const [tabActive, setTabActive] = useState(0);

  const contactsList = [
    {
      type: 0,
      title: `${StorageConstant.CONTACTS_TYPE_ARRAY[0].text}`,
      text: null,
    },
    {
      type: 1,
      title: `${StorageConstant.CONTACTS_TYPE_ARRAY[1].text}`,
      text: null,
    },
    {
      type: 2,
      title: `${StorageConstant.CONTACTS_TYPE_ARRAY[2].text}`,
      text: null,
    },
    {
      type: 3,
      title: `${StorageConstant.CONTACTS_TYPE_ARRAY[3].text}`,
      text: null,
    },
  ];
  const [order, setOrder] = useState({
    isEdit: false,
    title: "",
    text: "",
    type: 0,
    category: 1,
    sub_category: 0,
    price: 0,
    tags: "",
    sale: 0,
    sale_count: 0,
    user_id: 0,
    additional: [],
    images: [],
    contact: contactsList,
  });
  const [state, setState] = useState({
    isEditOpen: false,
    isLoad: false,
    isDialogCrop: false,
    isDialogCrop2: false,
    selectedCustomItem: null,
    customId: null,
    customIndex: null,
    addPhoto: null,
    addTitle: "S",
    addText: "",
    addPhotos: [],
    savedImages: [],
    removeArray: [],
    imagesList: [],
  });
  const [additional, setAdditional] = useState([]);
  const [additionalContact, setAdditionalContact] = useState([]);

  const [address, setAddress] = useState("");
  const [coord, setCoord] = useState([]);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const grid = 8;

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,
    background: isDragging ? "lightgreen" : "white",
    borderRadius: "10px",
    ...draggableStyle,
  });

  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "lightblue" : "lightgrey",
    padding: grid,
    width: "100%",
  });

  const clientsTitles = [
    { sort: false, title: "" },
    { sort: false, title: "" },
    { sort: false, title: "Изображение" },
    { sort: true, title: "Название" },
    { sort: false, title: "Начало" },
    { sort: false, title: "Окончание" },
  ];

  useEffect(() => {
    props.getCategoryList();
    props.getSubCategoryList();

    let editData = retrieveData("edit");
    if (editData) {
      let imgs = toArrayImagesListKey(editData.images);
      setState({ ...state, imagesList: imgs });
      editData.images = imgs;
      editData.contact = safelyParseJSON(editData.contact);
      let contactArr = [];
      contactsList.map((item) => {
        let ff = false;
        editData.contact.map((item2) => {
          if (parseInt(item2.type) == parseInt(item.type)) {
            ff = true;
            contactArr.push(item2);
          }
        });
        if (!ff) contactArr.push(item);
      });
      editData.contact = contactArr;
      editData.isEdit = true;

      setOrder(editData);
      getAdditional(editData.id);
      setAddress(editData.address);
    }
  }, []);

  useEffect(() => {
    if (props.login.user) {
      updateOrderObj("user_id", props.login.user.id);
    }
  }, [props.login.user]);

  useEffect(() => {
    if (coord.length > 0) {
      updateOrderObj("coord", coord.join(","));
      if (address.length > 0) updateOrderObj("address", address);
    }
  }, [coord]);

  function getAdditional(_id) {
    props.getPromoByStorage(_id).then((res) => {
      if (res.response) setAdditional(res.response);
    });
  }

  function uploadList(file) {
    addFile(file, 2).then((res) => {
      if (res.success) {
        setState({ ...state, addPhoto: res.response });
      }
    });
  }

  function onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      state.imagesList,
      result.source.index,
      result.destination.index
    );

    setState({
      ...state,
      imagesList: items,
    });
  }

  function convertArrayToAdditional(_arr) {
    let arr = [];
    _arr.map((item, itemIndex) => {
      arr.push([
        {
          button: "delete",
          openClick: () => {
            if (item.id)
              props.destroySpecialPromo(item.id).then(() => {
                getAdditional(order.id);
              });
            else {
              setState({ ...state, isLoad: true });

              let rem = additional;
              rem.splice(additional.length - 1, 1);
              setAdditional(rem);
              setState({ ...state, isLoad: false });
            }
          },
        },
        {
          button: "edit",
          openClick: () => {
            setState({
              ...state,
              isEditOpen: true,
              selectedCustomItem: item,
              customId: item.id ? item.id : null,
              customIndex: itemIndex,
            });
          },
        },
        {
          image: item.image
            ? convertImages(item.image, Env.PUBLIC_URL)[0]
            : null,
        },
        { text: item.title },
        { text: `${formatDateDMY(item.start_date)}  ` },
        { text: `${formatDateDMY(item.end_date)}` },
      ]);
    });
    return arr;
  }

 
  /**
   *
   * @param {string} key
   * @param {any} val
   */
  function updateOrderObj(key, val) {
    let _obj = order;
    _obj[key] = val;
    setOrder({ ..._obj });
  }

  function _onSaveImage(_croppedAreaPixels) {
    setState({ ...state, isDialogCrop: false });
    getCroppedImg(state.savedImages[0], _croppedAreaPixels, 0).then((res) => {
      setState({ ...state, selectedFileName: res });
      let arr = state.savedImages;
      arr.splice(0, 1);
      setState({ ...state, savedImages: arr });
      upload(res);
    });
  }

  function _onSaveImage2(_croppedAreaPixels) {
    setState({ ...state, isDialogCrop2: false });
    getCroppedImg(state.savedImages[0], _croppedAreaPixels, 0).then((res) => {
      setState({ ...state, selectedFileName2: res, addPhotos: [] });
      upload2(res);
    });
  }

  function getBase64(file, cb) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  }

  function upload(file) {
    setState({
      ...state,
      imageLoad: true,
    });
    addFileBase64(file, 0).then((res) => {
      setState({
        ...state,
        imageLoad: false,
      });
      if (res.success) {
        let arr = state.imagesList;
        arr.push({ id: (arr.length + 1).toString(), content: res.response });
        setState({
          ...state,
          imagesList: arr,
        });

        console.log(state.savedImages, arr);

        setState({
          ...state,
          isDialogCrop: state.savedImages.length > 0,
        });
      } else {
        console.log(res);
      }
    });
  }

  function upload2(file) {
    setState({
      ...state,
      imageLoad: true,
    });
    addFileBase64(file, 0).then((res) => {
      setState({
        ...state,
        imageLoad: false,
        isDialogCrop2: false,
      });
      if (res.success) {
        let arr = state.addPhotos;
        arr.push(res.response);
        setState({
          ...state,
          addPhotoPromo: res.response,
          savedImages: [],
          isDialogCrop2: false,
        });

        let _item = state.selectedCustomItem;
        _item.image = res.response;
        props.editSpecialPromo(_item).then(() => {
          getAdditional(order.id);
          setState({
            ...state,
            addPhotoPromo: null,
            savedImages: [],
            isDialogCrop2: false,
          });
        });
      } else {
        console.log(res);
      }
    });
  }

  function _remImg(index, id) {
    let arr = state.imagesList;
    let _removeArray = state.removeArray;
    _removeArray.push(id);
    arr.splice(index, 1);
    setState({
      ...state,
      removeArray: _removeArray,
      imagesList: arr,
    });
    updateOrderObj("imgs", arr);
  }

  function _remCustomImg(index, id) {
    setState({
      ...state,
      addPhotoPromo: null,
    });
  }

  function saveData(isNavigate = true){
    if (
      order.title != "" &&
      order.text != "" &&
      state.imagesList.length > 0
    ) {
      if (order.isEdit) {
        let _order = order;
        _order.additional = additional;
        _order.remove = state.removeArray;
        _order.images = fromArrayListKey(
          state.imagesList,
          "content"
        ); 
            props.update(_order).then((res) => {
              if (res.status == 200)
                navigate(
                  `/storages/category/${_order.category}`
                );
            });
       
      } else {
        let _order = order;
        _order.additional = additional;
        _order.images = fromArrayListKey(
          state.imagesList,
          "content"
        ); 
        props.addStorage(_order).then((res) => {
          if (res.status == 200) {
            if(isNavigate){
              navigate(
                `/storages/category/${_order.category}`
              );
            } else {
              let _order = order;
              _order.isEdit = false;
              _order.id = res.response.id;
              setOrder(_order);
            }
             
          }
        });
      }
      return true;
    } else {
      alert(
        "Заполните поля название, описание и фотографии"
      );
      return false;
    }
  }

  let tags = (order.tags ? order.tags.split(",") : []).map((item, index) => {
    return (
      <span key={index} className={OrderConstant.ORDER_TYPES_COLORS[index]}>
        {item}
      </span>
    );
  });

  return (
    <div id="page" className="product-add-page">
      <div className="product-add-tabs">
        <div className="product-add-tab">
          <UiButtonColor
            color={tabActive === 0 ? "amber" : "dark"}
            onClick={() => setTabActive(0)}
            small
            text="Информация об Объекте"
          />
        </div>
        <div className="product-add-tab">
          <UiButtonColor
            color={tabActive === 1 ? "blue" : "dark"}
            onClick={() => { 
              if(order.id){
                setTabActive(1)
              } else {
                if(saveData(false) )  setTabActive(1);
               
              }
             
            }}
            small
            text="Список Акций"
          />
        </div>
      </div>
      {tabActive === 0 && (
        <div className="product-add-wrap">
          <div className="product-add-about add">
            <div className="product-add-about-title">
              <div className="product-add-about-title-wrap">
                <h3>Информация об Объекте (создание / редактирование)</h3>
              </div>
            </div>
            <div className="product-add-notes">
              <div className="product-add-notes-list-wrap">
                <div className="product-add-info">
                  <div className="product-add-info-form">
                    <UiInputText
                      label="Название Объекта:"
                      placeholder="Название Объекта"
                      value={order.title}
                      onChange={(val) => {
                        updateOrderObj("title", val);
                      }}
                    />
                  </div>
                  <div className="product-add-info-form">
                    <UiDropDown
                      label="Категория:"
                      value={
                        props.category
                          ? props.category.list.length > 0
                            ? inArrayValueByKey(
                                props.category.list,
                                "id",
                                order.category
                              )?.id
                            : 0
                          : 0
                      }
                      items={toArrayListKey(
                        props.category ? props.category.list : [],
                        "title"
                      )}
                      onChange={(val) => updateOrderObj("category", val)}
                    />
                  </div>
                        {console.log(order)}
                  <div className="product-add-info-form">
                    <UiDropDown
                      label="Подкатегория:"
                      value={
                        props.category
                          ? props.category.subList.length > 0
                            ? inArrayValueByKey(
                                props.category.subList,
                                "id",
                                order.sub_category
                              )?.id
                            : 0
                          : 0
                      }
                      items={toArrayListKey(
                        props.category ? props.category.subList.filter((item) => { if(item.category_id == order.category) return item;}) : [],
                        "title"
                      )}
                      onChange={(val) => updateOrderObj("sub_category", val)}
                    />
                  </div>

                  <div className="product-add-info-form">
            
                  <UiDropDown
                        label="Выбрать Метку"
                        value={order.tags}
                      items={StorageConstant.TAGS_ARRAY}
                      onChange={(val) => updateOrderObj("tags", val)}
                    />
             
                    
                  </div>

                  <div className="product-add-info-form">
                    <UiTextArea
                      label="Краткое описание"
                      value={order.text}
                      onChange={(val) => updateOrderObj("text", val)}
                    />
                  </div>

                  <div className="product-add-info-form">
                    <DragDropContext onDragEnd={onDragEnd}>
                      <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                          <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            className="storage-add-images-draggable"
                            style={getListStyle(snapshot.isDraggingOver)}
                          >
                            {state.imagesList.map((item, index) => (
                              <Draggable
                                key={item.id}
                                draggableId={item.id}
                                index={index}
                              >
                                {(provided, snapshot) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={getItemStyle(
                                      snapshot.isDragging,
                                      provided.draggableProps.style
                                    )}
                                  >
                                    <UiCardAddPhoto
                                      key={index}
                                      image={Env.PUBLIC_URL + item.content}
                                      remove={() => {
                                        _remImg(index, item.content);
                                      }}
                                    />
                                  </div>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </div>

                  <div className="product-add-info-form product-add-info-photo">
                    <UiCardAddPhoto
                      upload={(e) => {
                        if (e.target.files.length > 0) {
                          for (let i = 0; i < e.target.files.length; i++) {
                            let arr = state.savedImages;
                            getBase64(e.target.files[i], (result) => {
                              arr.push(result);
                              setState({
                                ...state,
                                savedImages: arr,
                                base64Image: result,
                                isDialogCrop: true,
                              });
                            });
                          }
                        }
                      }}
                    />
                  </div>

                  <div className="product-add-info-form product-add-info-photo">
                    <UiButtonColor
                      color="indigo"
                      text={order.isEdit ? "Сохранить" : "Создать"}
                      small={true}
                      onClick={() => {
                        saveData();
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="product-add-info-wrap">
            <div className="product-add-info">
              <div className="product-add-info-form">
                <UiMapAddress
                  address={order.address}
                  onChangeAddress={(line) => {
                    setAddress(line);
                  }}
                  onChange={(t) => {
                    if (t.length > 1) {
                      setCoord(t);
                    }
                  }}
                />
                <UiInputText
                  label="Адрес объекта"
                  value={order.address}
                  onChange={(val) => updateOrderObj("address", val)}
                />
              </div>

              <div className="product-add-info-form">
                {order.contact.map((contactItem, contactIndex) => {
                  return contactItem.type == 0 ? (
                    <UiTextInputMasked
                      label="Телефон"
                      placeholder="Введите Телефон.."
                      value={contactItem.text}
                      onChange={(val) => {
                        let arrContact = order.contact;
                        arrContact[contactIndex].text = val;
                        updateOrderObj("contact", arrContact);
                      }}
                    />
                  ) : (
                    <UiInputText
                      label={contactItem.title}
                      placeholder={`Введите ссылку ${
                        StorageConstant.CONTACTS_TYPE_ARRAY[contactItem.type]
                          .text
                      }`}
                      value={contactItem.text}
                      onChange={(val) => {
                        let arrContact = order.contact;
                        arrContact[contactIndex].text = val;
                        updateOrderObj("contact", arrContact);
                      }}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      )}

      {tabActive === 1 && (
        <div className="product-add-materials-wrap">
          <div className="product-add-info">
            <div className="product-add-materials-title">
              <h3>Список Акций Объекта</h3>
              <div>
                <UiButtonColor
                  color="blue"
                  text="+ Добавить акцию"
                  small={true}
                  onClick={() => {
                    setState({ ...state, selectedCustomItem:  null, 
                    customId: null, 
                    isEditOpen: true });
                   
                  }}
                />
              </div>
            </div>
            <div className="added-list">
    
                <UiTableSmall
                  titleList={clientsTitles}
                  grid={clientsTitles.length}
                  tableList={convertArrayToAdditional(additional)}
                  search={false}
                />
       
            </div>
   
          </div>
        </div>
      )}

      <UiModalImageCrop
        modalOpen={state.isDialogCrop}
        base64Image={state.savedImages[0]}
        onSave={(val) => _onSaveImage(val)}
        cancelClick={() => setState({ ...state, isDialogCrop: false })}
      />

      <UiModalImageCrop
        modalOpen={state.isDialogCrop2}
        base64Image={state.savedImages[0]}
        onSave={(val) => _onSaveImage2(val)}
        cancelClick={() => setState({ ...state, isDialogCrop2: false })}
      />

      <UiModalEditComposition
        modalOpen={state.isEditOpen}
        item={state.selectedCustomItem}
        onSave={(val) => {
          setState({ ...state, isEditOpen: false }); 
          if (state.customId)
            props.editSpecialPromo(val).then(() => {
              getAdditional(order.id);
            });
          else { 
            setState({ ...state,  isEditOpen: false}); 
            val.storage_id = order.id;
            props.addSpecialPromo(val).then(() => {
              getAdditional(order.id);
            })

          }
        }}
        onChangeImage={(arr) => {
           
        }}
        cancelClick={() => setState({ ...state, isEditOpen: false })}
      />
    </div>
  );
}

export default connect(({ category, login }) => ({ category, login }), {
  getSubCategoryList: getSubCategoryListAction,
  getCategoryList: getCategoryListAction,
  addStorage: addStorageAction,
  update: updateAction,

  addSpecialPromo: addSpecialPromoAction,
  destroySpecialPromo: destroySpecialPromoAction,
  getPromoByStorage: getPromoByStorageAction,
  editSpecialPromo: editSpecialPromoAction,
})(StoragesAddPage);
