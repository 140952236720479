export default {
  ORDER: {
    isNew: true,
    id: null,
    status: 0,
    price: 0,
    price_discount: 0,
    price_delivery: 0,
    payment_type: 0,
    moving_types: 0,
    user_ps: 1,
    distance: 1,
    is_pay: 0,
    order_type: 0,
    type: 0,
    sale: 0,
    sale_count: 0,
    composition: "",
    photos: "",
    user_phone: "",
    user_call: 0,
    phone: "",
    name: "",
    date: "",
    time: "0:00 - 1:00",
    order_name: "",
    address_a: "",
    elevator_type_a: 0,
    floor_a: "",
    text: "",
    postcard: "",
    comment: "",
  },

  ORDER_TYPES_MANAGER_NAMES: [
    "Новый заказ",
    "ЗАВЕРШИТЬ ЗАКАЗ", 
  ],

  ORDER_TYPES_NAMES: [
    "Новый заказ",
    "Оформлен",
    "Готов",
    "Доставляется",
    "Выполнен / Доставлен", 
    "Отменен",
  ],

  
  ORDER_PAYMENTS_TYPES_NAMES: [
    "Оплата картой VISA\MasterCard",
    "Наличные",
    "Терминал",
    "Выездной терминал",
    "Оплата курьеру",
    "Перевод на карту Сбербанка",
    "Оплата юр. лицом",
  ],
  ORDER_DELIVERY_TYPES_NAMES: [
    "Доставка",
    "Самовывоз",
  ],
  ORDER_CALL_TYPES_NAMES: [
    "Доставка-сюрприз",
    "Предварительный звонок",
  ],
  ORDER_IS_PAY: [
    "Ожидает",
    "Оплачен",
  ],

  SHIPMENT_POINT: [
    "Комсомольский",
    "Билецкого",
  ],

  ORDER_SELF_DELIVERY_NAMES: [ 
      "Комсомольский проспект, 11",
      "​Семёна Билецкого, 1",
  ],

  ORDER_TIME_TYPE: [
    "0:00 - 1:00",
    "1:00 - 2:00",
    "2:00 - 3:00",
    "3:00 - 4:00",
    "4:00 - 5:00",
    "5:00 - 6:00",
    "6:00 - 7:00",
    "7:00 - 8:00",
    "8:00 - 9:00",
    "9:00 - 10:00",
    "10:00 - 11:00",
    "11:00 - 12:00",
    "12:00 - 13:00",
    "13:00 - 14:00",
    "14:00 - 15:00",
    "15:00 - 16:00",
    "16:00 - 17:00",
    "17:00 - 18:00",
    "18:00 - 19:00",
    "19:00 - 20:00",
    "20:00 - 21:00",
    "21:00 - 22:00",
    "22:00 - 23:00",
    "23:00 - 24:00",
  ],

  ORDER_STATUS_NEW: 0,
  ORDER_STATUS_WAIT: 1,
  ORDER_STATUS_SEND: 2,
  ORDER_STATUS_CONTROL: 3,
  ORDER_STATUS_RAIT: 4,
  ORDER_STATUS_DONE: 4,

  ORDER_TYPES_COLORS: [
    "orange", "green", "cyan", 
    "sky", "violet", "gray", "red",
  ],

  UPDATE_INFO_NEW_STATUS: 'new_status',
  UPDATE_INFO_CHANGE_INFO: 'change_info',
  UPDATE_INFO_CHANGE_PRICE: 'change_price',
  UPDATE_INFO_NEW_WORKER: 'new_worker',

  TIME_OFFSET: [
    { value: null, text: "Сочи (GMT +3)" },
    { value: 2, text: "Калининград (GMT +2)" },
    { value: 3, text: "Москва (GMT +3)" },
    { value: 4, text: "Самара (GMT +4)" },
    { value: 5, text: "Екатеринбург (GMT +5)" },
    { value: 6, text: "Омск (GMT +6)" },
    { value: 7, text: "Новосибирск (GMT +7)" },
    { value: 8, text: "Иркутск (GMT +8)" },
    { value: 9, text: "Чита (GMT +9)" },
    { value: 10, text: "Хабаровск (GMT +10)" },
    { value: 11, text: "Магадан (GMT +11)" },
    { value: 12, text: "Петропавловск-Камчатский (GMT +12)" },
  ],
};