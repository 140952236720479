import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { addFile, retrieveData } from "../../services/Storage";
import { useNavigate, useParams } from "react-router-dom";
 
import UiInputText from "../../components/inputs/UiInputText"; 
import UiDropDown from "../../components/inputs/UiDropDown"; 
import UiButtonColor from "../../components/button/UiButtonColor"; 

import UiTextAreaWysiwyg from "../../components/inputs/UiTextAreaWysiwyg";

import {
  addCategory as addCategoryAction,
  update as updateAction,
} from "../../redux/modules/category";

import "./StoragesStyle.scss";

function StoragesCategoryEditPage(props) {
  const { id } = useParams();
  const navigate = useNavigate();
  const [order, setOrder] = useState({
    isEdit: false,
    title: "",
    full_title: "",
    description: "",
    category: 0,
    is_menu: 1,
    is_site_menu: 0,
    description: "",
  });
  const [state, setState] = useState({
    isLoad: false,
    isDialogCrop: false,
    savedImages: [],
    removeArray: [],
    imagesList: [],
  });
  const [description, setDescription] = useState("");

  useEffect(() => {
    let editData = retrieveData("editCategory");
    if (editData) {
      setOrder(editData);
      setDescription(editData.description)
    }
  }, [id]);

  /**
   *
   * @param {string} key
   * @param {any} val
   */
  function updateOrderObj(key, val) {
    let _obj = order;
    _obj[key] = val;
    setOrder({ ..._obj });
  }

  return (
    <div id="page" className="product-add-page">
      <div className="product-add-wrap">
        <div className="product-add-about add">
          <div className="product-add-about-title">
            <div className="product-add-about-title-wrap">
              <h3>Редактировать категорию</h3>
            </div>
          </div>
          <div className="product-add-notes">
            <div className="product-add-notes-list-wrap">
              <div className="product-add-info">
                <div className="product-add-info-form">
                  <UiInputText
                    label="Название:"
                    placeholder="Название"
                    value={order.title}
                    onChange={(val) => {
                      updateOrderObj("title", val);
                    }}
                  />
                </div>

           
                <div className="product-add-info-form">
            
                  <UiTextAreaWysiwyg
                    initValue={order.description}
                    onChange={(val) => {
                      setDescription(val)
                    }}
                  />
                </div>

                 
                <div className="product-add-info-form">
                  <UiDropDown
                    value={order.is_menu}
                    label="Показать в меню:"
                    items={[
                      { value: 0, text: "Скрыть" },
                      { value: 1, text: "Показать" },
                    ]}
                    onChange={(val) => updateOrderObj("is_menu", val)}
                  />
                </div>
                <div className="product-add-info-form">
                  <UiDropDown
                    label="Показать на главной:"
                    value={order.is_site_menu}
                    items={[
                      { value: 0, text: "Скрыть" },
                      { value: 1, text: "Показать" },
                    ]}
                    onChange={(val) => updateOrderObj("is_site_menu", val)}
                  />
                </div>

                <div className="product-add-info-form product-add-info-photo">
                  <UiButtonColor
                    color="primary"
                    text={"Сохранит изменения и Закрыть"}
                    small={true}
                    onClick={() => {
                      if (order.title != "") {
                        let _item = order;
                        _item.description = description;
                        props.update(_item).then((res) => {
                          if (res) navigate(`/category/all`);
                        });
                      } else {
                        alert("Заполните поля название  ");
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default connect(({ category }) => ({ category }), {
  update: updateAction,
})(StoragesCategoryEditPage);
