import React, { useEffect, useState } from "react";
import { addFile, convertImages } from "../../services/Storage";
import { toArrayList, toArrayListKey, inArrayValueByKey } from "../../utilities/array";


import UiInputText from "../inputs/UiInputText";
import UiModal from "./UiModal";

import "./UiModalDeal.scss";
import "./UiModalUser.scss";
import UiDropDown from "../inputs/UiDropDown";
import UiTextInputMasked from "../inputs/UiTextInputMasked";
import UiCardAddPhoto from "../cards/UiCardAddPhoto";
import UiButtonColor from "../button/UiButtonColor";
import Env from "../../services/Env";
import UserConstant from "../../constants/UserConstant";

function UiModalManager(props) {
  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [phone, setPhone] = useState(null);
  const [contact, setContact] = useState(null);
  const [comment, setComment] = useState(null);
  const [rating, setRating] = useState(null);
  const [type, setType] = useState(0);

  const [paymentInfo, setPaymentInfo] = useState(null);
  const [fContact, setFContact] = useState(null);
  const [car, setCar] = useState(null);
  const [carSize, setCarSize] = useState(null);
  const [carCarrying, setCarCarrying] = useState(null);
  const [carVolume, setCarVolume] = useState(null);

  const [pass, setPass] = useState(null);

  const [images, setImages] = useState([]);
  const [imageUrl, setImageUrl] = useState("");
  const [load, setLoad] = useState(false);

 
  useEffect(() => {
    if (props.data != null) {
      setName(props.data.name);
      setPhone(props.data.phone);
      setPass(props.data.password);

      setType(props.data.type);
      setEmail(props.data.email);
      setContact(props.data.contact);
      setImages(convertImages(props.data.images));
      setImageUrl(props.data.images ? Env.PUBLIC_URL + props.data.images : null);
      setComment(props.data.comment);
      setRating(props.data.rating);
      setPaymentInfo(props.data.payment_info);
      setFContact(props.data.fact_contact);
      setCar(props.data.car);
      setCarSize(props.data.car_size);
      setCarCarrying(props.data.car_carrying);
      setCarVolume(props.data.car_volume);

    } else {
      setName(null);
      setPhone(null);
      setPass(null);
      setContact(null);
      setEmail(`user${Math.floor(Date.now() / 10000)}`);
      setImages([]);
      setComment(null);
      setImageUrl(null);
      setRating(null);
      setPaymentInfo(null);
      setFContact(null);
      setCar(null);
      setCarSize(null);
      setCarCarrying(null);
      setCarVolume(null);
    }
  }, [props.data]);


  return (
    <UiModal name="modal" show={props.modalActive}>
      <div className="modal-deal">
        <div className="modal-deal-wrap">
          {/**
           * modal left side - static
           */}
          <div className="modal-deal-about add">
            {/**
             * modal title
             */}
            <div className="modal-deal-about-title">
              <div className="modal-deal-about-title-wrap">
                <h3>
                  {props.data ? "Информация о работнике" : "Создать работника"}
                </h3>
              </div>
              <div className="modal-deal-about-buttons"> </div>
            </div>
            {/**
             * modal notifications + msgs
             */}
            <div className="modal-deal-notes">
              <div className="modal-deal-notes-list-wrap">
                <div className="modal-deal-info">
                  <div className="modal-deal-info-form">
                    <UiInputText
                      label="Имя Фамилия"
                      value={name}
                      onChange={(val) => setName(val)}
                    />
                  </div>
                  <div className="modal-deal-info-form">
                    <UiInputText
                      label="Email - логин"
                      value={email}
                      onChange={(val) => setEmail(val)}
                    />
                  </div>

                  <div className="modal-deal-info-form">
                    <UiInputText
                      label="Пароль"
                      value={pass}
                      onChange={(val) => setPass(val)}
                    />
                  </div>


                  <div className="modal-deal-info-form">

                    <UiTextInputMasked
                      label="Телефон"
                      placeholder="Введите Телефон.."
                      value={phone}
                      onChange={(val) => {
                        var str = val.replace(/[^0-9]/g, '');
                        setPhone(str)
                      }}
                    />

                  </div>
                  <div className="modal-deal-info-form">

                    <UiDropDown
                      label="Тип пользователя"
                      value={type}
                      items={toArrayList(UserConstant.USERS_TYPE_LIST)}
                      onChange={(val) => setType(val)}
                    />

                  </div>


                  <div className="modal-deal-info-form">
                    {props.data ? (
                      <UiButtonColor
                        color="indigo"
                        text="Обновить"
                        small={false}
                        onClick={() => {
                          props.onEdit({
                            id: props.data.id,
                            name: name,
                            email: email,
                            password: pass,
                            phone: phone,
                            type: type,
                            contact: contact,
                            comment: comment,
                            image: images,
                            rating: rating,
                            payment_info: paymentInfo,
                            fact_contact: fContact,
                            car: car,
                            car_size: carSize,
                            car_carrying: carCarrying,
                            car_volume: carVolume,
                            is_worker: type == 4 ? 1 : 0,
                            is_del: 0
                          });
                        }}
                      />
                    ) : (
                      <UiButtonColor
                        color="indigo"
                        text="Создать"
                        small={false}
                        onClick={() => {
                          props.onCreate({
                            name: name,
                            phone: phone,
                            email: email,
                            type: type,
                            contact: contact,
                            comment: comment,
                            image: images,
                            payment_info: paymentInfo,
                            fact_contact: fContact,
                            car: car,
                            car_size: carSize,
                            car_carrying: carCarrying,
                            car_volume: carVolume,
                            is_worker: type == 4 ? 1 : 0
                          });
                        }}
                      />
                    )}
                  </div>

                  <div className="modal-deal-info-form">
                    <UiButtonColor
                      color="red"
                      text="Закрыть"
                      small={false}
                      onClick={() => {
                         props.modalClose()
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </UiModal>
  );
}

export default UiModalManager;
