import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import {
  searchStorage as searchStorageAction,
  getStorageList as getStorageListAction,
  getStorageListByCat as getStorageListByCatAction,
  addStorage as addStorageAction,
  update as updateStorageAction,
} from "../../redux/modules/storage";
import { changeConfirmState as changeConfirmStateAction } from "../../redux/modules/login";
import {
  convertImages,
  fromArrayListKey,
  inArrayValueByKey,
} from "../../utilities/array";

import UiInputSearch from "../../components/inputs/UiInputSearch";
import UiButtonColor from "../../components/button/UiButtonColor";
import UiModalProductAdd from "../../components/modals/UiModalProductAdd";
import UiPageText from "../../components/text/UiPageText";
import UiTableAdaptive from "../../components/table/UiTableAdaptive";
import UiDialog from "../../components/modals/UiDialog";

import "./StoragesStyle.scss";
import Env from "../../services/Env";
import { storeData } from "../../services/Storage";

import StorageConstant from "../../constants/StorageConstant";

function StoragesPage(props) {
  const { id } = useParams();
  const navigate = useNavigate();

  const [showDialog, setShowDialog] = useState(false);
  const [selectedStorage, setSelectedStorage] = useState(null);
  const [modalDealAddACtive, setModalDealAddActive] = useState(false);
  const [searchLine, setSearchLine] = useState(null);

  const clientsTitles = [
    { sort: false, title: "" },
    { sort: true, title: "Сортировка" },
    { sort: false, title: "Фото" },
    { sort: true, title: "Номер" },
    { sort: false, title: "Название" },
    { sort: false, title: "Описание" },
    { sort: true, title: "Категория" },
    { sort: true, title: "Скрыть" },
    { sort: true, title: "Статус" },

    { sort: false, title: "Удалить" },
  ];
  const [tableListOrders, setTableListOrders] = useState([]);

  function _load() {
    if (id == "all") props.getStorageList();
    else props.getStorageListByCat(id);
  }

  function search(line) {
    if (line.length > 0) props.searchStorage(line);
    else _load();
  }

  useEffect(() => {
    _load();
  }, [id]);

  useEffect(() => {
    let arr = [];

    props.storage.list.map((item) => {
      let images = convertImages(item.imgs);
      item.images = images;
      let _item = [
        {
          inputSort: `${item.sort}`,
          onBlurInput: (v) => {
            let _item = item;
            let s = _item.sort;
            if (v == 1) {
              s++;
              _item.sort = s;
            } else {
              s--;
              _item.sort = s;
            }
            props.update(_item).then(() => _load());
          },
        },
        { image: images[0] ? Env.PUBLIC_URL + images[0] : null },

        { text: item.id },
        { text: item.title },
        { text: item.text },
        {
          statusInfo:
            props.category.list.length > 0
              ? inArrayValueByKey(props.category.list, "id", item.category)
                  .title
              : "",
          statusColor: "orange",
        },
        {
          button: item.is_active == 0 ? "eye-open" : "eye",
          onClick: () => {
            let _item = item;
            _item.is_active = item.is_active == 1 ? 0 : 1;
            props.update(_item).then(() => _load());
          },
        },
      ];
      if (props.login.user?.type == 3) {
        _item.push({
          statusInfo: item.status == 0 ? "На рассмотрении" : "Одобрен",
          statusColor: item.status == 0 ? "red" : "green",
        });
      } else {
        _item.push({
          statusInfo: StorageConstant.CONFIRM_STATUS[item.is_confirm],
          statusColor: StorageConstant.CONFIRM_STATUS_COLORS[item.is_confirm],
          statusList: StorageConstant.CONFIRM_STATUS,
          onStatusChange: (val) => {
            console.log(val);
            let _item = item;
            _item.is_confirm = val;

            props.update(_item).then((res) => {
              if (res.status == 200) _load();
            });
          },
        });
      }
      _item.unshift({
        button: "edit",
        onClick: () => {
          storeData("edit", item);
          navigate("/storages/add");
        },
      });

      _item.push({
        button: "delete",
        onClick: () => {
          props.changeConfirmState(
            true,
            `Удалить категорию "${item.title}" ?`,
            () => {
              let _item = item;
              _item.is_del = 1;
              props.update(_item).then((res) => {
                if (res.status == 200) _load();
              });
            }
          );
        },
      });
      arr.push(_item);
    });

    setTableListOrders(arr);
  }, [props.storage.list, props.category.list]);

  return (
    <div id="page" className="home-page">
      <section className="home-info ui-grid">
        <div className="home-info-text">
          <UiPageText
            title={`Список объектов в категории: ${
              props.category.list.length > 0
                ? inArrayValueByKey(props.category.list, "id", id).title
                : ""
            }`}
          />
        </div>
        <div className="home-info-button">
          <UiButtonColor
            color="indigo"
            text="Добавить"
            small={true}
            onClick={() => {
              storeData("edit", null);
              navigate("/storages/add");
            }}
          />
        </div>
      </section>
      <section className="page-content">
        <div className="page-search">
          <UiInputSearch
            placeholder="Поиск по названию:"
            onChange={(val) => search(val)}
          />
        </div>

        <UiTableAdaptive
          titleList={clientsTitles}
          grid={clientsTitles.length}
          numOfLines={4}
          tableList={tableListOrders}
          scroll={true}
          search={searchLine}
        />
      </section>
      {/* modal deal add */}
      <UiModalProductAdd
        modalActive={modalDealAddACtive}
        modalClose={() => setModalDealAddActive(false)}
        findUserEvent={props.getUsersByPhone}
        prices={props.settings.prices}
        onCreate={(data) => {
          props.addOrder(data).then(() => {
            setModalDealAddActive(false);
          });
        }}
      />
    </div>
  );
}

export default connect(
  ({ storage, login, comment, logs, user, settings, category }) => ({
    storage,
    login,
    comment,
    logs,
    user,
    settings,
    category,
  }),
  {
    changeConfirmState: changeConfirmStateAction,
    getStorageList: getStorageListAction,
    getStorageListByCat: getStorageListByCatAction,
    addStorage: addStorageAction,
    searchStorage: searchStorageAction,
    update: updateStorageAction,
  }
)(StoragesPage);
