import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import demo0 from "../../assets/images/demo/1447_b.jpeg";

import OrderConstant from "../../constants/OrderConstant";

import {
  getStorageList as getStorageListAction,
  getStorageListByCat as getStorageListByCatAction,
  addStorage as addStorageAction,
} from "../../redux/modules/storage";

import {
  getCategoryList as getCategoryListAction,
  update as updateAction,
} from "../../redux/modules/category";
import { changeConfirmState as changeConfirmStateAction } from "../../redux/modules/login";
import { convertImages, fromArrayListKey } from "../../utilities/array";

import UiInputSearch from "../../components/inputs/UiInputSearch";
import UiButtonColor from "../../components/button/UiButtonColor";
import UiModalProductAdd from "../../components/modals/UiModalProductAdd";
import UiPageText from "../../components/text/UiPageText";
import UiTableAdaptive from "../../components/table/UiTableAdaptive";
import UiDialog from "../../components/modals/UiDialog";

import "./StoragesStyle.scss";
import Env from "../../services/Env";
import { storeData } from "../../services/Storage";

function StoragesCategorys(props) {
  const navigate = useNavigate();

  const [showDialog, setShowDialog] = useState(false);
  const [selectedStorage, setSelectedStorage] = useState(null);
  const [searchLine, setSearchLine] = useState(null);

  const clientsTitles = [
    { sort: false, title: "" },
    { sort: true, title: "Номер" },
    { sort: false, title: "Название" },
    { sort: true, title: "Показать в меню" },
    { sort: true, title: "Показать на главной" },
    { sort: false, title: "Удалить" },
  ];
  const [tableListOrders, setTableListOrders] = useState([]);

  useEffect(() => {
    props.getCategoryList();
  }, []);

  useEffect(() => {
    let arr = [];
    props.category.list.map((item) => {
      let images = convertImages(item.imgs);
      item.images = images;
      arr.push([
        {
          button: "edit",
          onClick: () => {
            storeData("editCategory", item);
            navigate(`/edit/category/${item.id}`);
          },
        },

        { text: item.id },
        { text: item.title },
        { text: item.is_menu == 1 ? "Да" : "Нет" },
        {
          button: item.is_site_menu ? "eye-open" : "eye",
          color:  item.is_site_menu == 1 ? "gray" : "red",
          onClick: () => {
            let _item = item;
            _item.is_site_menu = _item.is_site_menu == 1 ? 0 : 1;
            props.update(_item);
          },
        },
        {
          button: "delete",
          color: "blue",
          onClick: () => {
            setSelectedStorage(item);
            setShowDialog(true);

            props.changeConfirmState(
              true,
              `Удалить категорию "${item.title}" ?`,
              () => {
                let _item = item;
                _item.is_del = 1;
                props.update(_item).then((res) => {
                  if (res.status == 200) props.getCategoryList();
                });
              }
            );
          },
        },
      ]);
    });
    setTableListOrders(arr);
  }, [props.category.list]);

  return (
    <div id="page" className="home-page">
      <section className="home-info ui-grid">
        <div className="home-info-text">
          <UiPageText title="Список категорий" />
        </div>
        <div className="home-info-button">
          <UiButtonColor
            color="primary"
            text="Добавить"
            small={true}
            onClick={() => {
              storeData("edit", null);
              navigate("/add/category");
            }}
          />
        </div>
      </section>
      <section className="page-content">
        <div className="page-search">
          <UiInputSearch
            label="Список категорий"
            placeholder="Поиск:"
            onChange={(val) => setSearchLine(val)}
          />
        </div>

        <UiTableAdaptive
          titleList={clientsTitles}
          grid={clientsTitles.length}
          tableList={tableListOrders}
          scroll={true}
          search={searchLine}
        />
      </section>

      <UiDialog
        onConfirm={() => {
          let _item = selectedStorage;
          _item.is_del = 1;
          props.update(_item);
        }}
        show={true}
      />
    </div>
  );
}

export default connect(
  ({ storage, login, comment, logs, user, settings, category }) => ({
    storage,
    login,
    comment,
    logs,
    user,
    settings,
    category,
  }),
  {
    changeConfirmState: changeConfirmStateAction,

    getCategoryList: getCategoryListAction,
    getStorageList: getStorageListAction,
    getStorageListByCat: getStorageListByCatAction,
    addStorage: addStorageAction,
    update: updateAction,
  }
)(StoragesCategorys);
