import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import demo0 from "../../assets/images/demo/1447_b.jpeg";

import OrderConstant from "../../constants/OrderConstant";

import {
  getStorageList as getStorageListAction,
  getStorageListByCat as getStorageListByCatAction,
  addStorage as addStorageAction,
} from "../../redux/modules/storage";

import {
  getCategoryList as getCategoryListAction,
  getSubCategoryList as getSubCategoryListAction,
  update as updateAction,
  updateSub as updateSubAction,
} from "../../redux/modules/category";

import { changeConfirmState as changeConfirmStateAction } from "../../redux/modules/login";
import { convertImages,  inArrayValueByKey } from "../../utilities/array";

import UiInputSearch from "../../components/inputs/UiInputSearch";
import UiButtonColor from "../../components/button/UiButtonColor";
import UiModalProductAdd from "../../components/modals/UiModalProductAdd";
import UiPageText from "../../components/text/UiPageText";
import UiTableAdaptive from "../../components/table/UiTableAdaptive";
import UiDialog from "../../components/modals/UiDialog";

import "./StoragesStyle.scss";
import Env from "../../services/Env";
import { storeData } from "../../services/Storage";

function StoragesSubCategorys(props) {
  const navigate = useNavigate();

  const [catList, setCatList] = useState([]);
  const [categories, setCategories] = useState([]);
  const [showDialog, setShowDialog] = useState(false);
  const [selectedStorage, setSelectedStorage] = useState(null);
  const [searchLine, setSearchLine] = useState(null);

  const clientsTitles = [
    { sort: false, title: "" },
    { sort: true, title: "Родительская категория" },
    { sort: false, title: "Название" },
    { sort: false, title: "Удалить" },
  ];
  const [tableListOrders, setTableListOrders] = useState([]);

  useEffect(() => { 
    props.getCategoryList().then((data) => {
      setCategories(data);

      props.getSubCategoryList().then((data) => {
        setCatList(data);
      });

    });
  }, []);

  useEffect(() => {
    let arr = [];
    catList.map((item) => {
      let images = convertImages(item.imgs);
      item.images = images;
      arr.push([
        {
          button: "edit",
          color: "blue",
          onClick: () => {
            storeData("editCategory", item);
            navigate(`/edit/subcategory/${item.id}`);
          },
        },
        {
          text: inArrayValueByKey(categories, "id", item.category_id)?.title,
        },
        { text: item.title },

        {
          button: "delete",
          color: "blue",
          onClick: () => {
            setSelectedStorage(item);
            setShowDialog(true);

            props.changeConfirmState(
              true,
              `Удалить под категорию "${item.title}" ?`,
              () => {
                let _item = item;
                _item.is_del = 1;
                props.updateSub(_item).then((res) => {
                  if (res) props.getSubCategoryList();
                });
              }
            );
          },
        },
      ]);
    });
    setTableListOrders(arr);
  }, [catList]);

  return (
    <div id="page" className="home-page">
      <section className="home-info ui-grid">
        <div className="home-info-text">
          <UiPageText title="Список под категорий" />
        </div>
        <div className="home-info-button">
          <UiButtonColor
            color="primary"
            text="Добавить"
            small={true}
            onClick={() => {
              storeData("editCategory", null);
              navigate("/add/subcategory");
            }}
          />
        </div>
      </section>
      <section className="page-content">
        <div className="page-search">
          <UiInputSearch
            label="Список под категорий"
            placeholder="Поиск:"
            onChange={(val) => setSearchLine(val)}
          />
        </div>

        <UiTableAdaptive
          titleList={clientsTitles}
          grid={clientsTitles.length}
          tableList={tableListOrders}
          scroll={true}
          search={searchLine}
        />
      </section>

      <UiDialog
        onConfirm={() => {
          let _item = selectedStorage;
          _item.is_del = 1;
          props.update(_item);
        }}
        show={true}
      />
    </div>
  );
}

export default connect(
  ({ storage, login, comment, logs, user, settings, category }) => ({
    storage,
    login,
    comment,
    logs,
    user,
    settings,
    category,
  }),
  {
    changeConfirmState: changeConfirmStateAction,

    getSubCategoryList: getSubCategoryListAction,
    getStorageList: getStorageListAction,
    getStorageListByCat: getStorageListByCatAction,
    getCategoryList: getCategoryListAction,
    addStorage: addStorageAction,
    update: updateAction,
    updateSub: updateSubAction,
  }
)(StoragesSubCategorys);
