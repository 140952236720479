import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { connect } from "react-redux";

import { ReactComponent as SvgIsland } from "../../assets/images/ui/island.svg";

import UiInputSearch from "../../components/inputs/UiInputSearch";
import UiButtonColor from "../../components/button/UiButtonColor";
import UiDialog from "../../components/modals/UiDialog";

import UiPageText from "../../components/text/UiPageText";
import UiTableAdaptive from "../../components/table/UiTableAdaptive";

import StorageConstant from "../../constants/StorageConstant";

import { setLoaderState as setLoaderStateAction } from "../../redux/modules/order";
import { changeConfirmState as changeConfirmStateAction } from "../../redux/modules/login";

import {
  getStorageListByIdArray as getStorageListByIdArrayAction,
  getStorageList as getStorageListAction,
  searchStorage as searchStorageAction,
  update as updateAction,
} from "../../redux/modules/storage";

import { convertImages, inArrayValueByKey } from "../../utilities/array";
import { storeData } from "../../services/Storage";
import Env from "../../services/Env";

import "./HomeStyle.scss";

function HomePage(props) {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);

  const [searchLine, setSearchLine] = useState(null);
  const [searchLine2, setSearchLine2] = useState(null);

  const clientsTitles = [
    { sort: false, title: "" },
    { sort: false, title: "Фото" },
    { sort: true, title: "Номер" },
    { sort: false, title: "Название" },
    { sort: false, title: "Описание" },
    { sort: true, title: "Категория" },
    { sort: false, title: "Скрыть" },
    { sort: true, title: "Статус" },
    { sort: false, title: "" },
  ];
  const [tableListOrders, setTableListOrders] = useState([]);

  function _load() {
    if (props.login.user) {
      setUser(props.login.user);
      props.getStorageList(
        props.login.user.type == 3 ? props.login.user.id : "all"
      );
    }
  }

  useEffect(() => {
    _load();
  }, [props.login.user]);

  useEffect(() => {
    if (props.storage.list) {
      let arr = [];

      props.storage.list.map((item) => {
        let images = convertImages(item.imgs);
        item.images = images;
        let _item = [
          { image: images[0] ? Env.PUBLIC_URL + images[0] : null },

          { text: item.id },
          { text: item.title },
          { text: item.text },
          {
            statusInfo:
              props.category.list.length > 0
                ? inArrayValueByKey(props.category.list, "id", item.category)
                    ?.title
                : "",
            statusColor: "orange",
          },
          {
            button: item.is_active == 0 ? "eye-open" : "eye",
            onClick: () => {
              let _item = item;
              _item.is_active = item.is_active == 1 ? 0 : 1;
              props.update(_item).then(() => _load());
            },
          },
        ];
        if (props.login.user.type == 3) {
          _item.push({
            statusInfo: item.status == 0 ? "На рассмотрении" : "Одобрен",
            statusColor: item.status == 0 ? "red" : "green",
          });
        } else {
          _item.push({
            statusInfo: StorageConstant.CONFIRM_STATUS[item.is_confirm],
            statusColor: StorageConstant.CONFIRM_STATUS_COLORS[item.is_confirm],
            statusList: StorageConstant.CONFIRM_STATUS,
            onStatusChange: (val) => {
              let _item = item;
              _item.is_confirm = val;
              props.update(_item).then((res) => {
                if (res.status == 200) _load();
              });
            },
          });
        }
        _item.unshift({
          button: "edit",
          onClick: () => {
            storeData("edit", item);
            navigate("/storages/add");
          },
        });

        _item.push({
          button: "delete",
          onClick: () => {
            props.changeConfirmState(
              true,
              `Удалить категорию "${item.title}" ?`,
              () => {
                let _item = item;
                _item.is_del = 1;
                props.update(_item).then((res) => {
                  if (res.status == 200) _load();
                });
              }
            );
          },
        });
        arr.push(_item);
      });

      setTableListOrders(arr);
      props.setLoaderState(false);
    }
  }, [props.storage.list, props.category.list]);

  function searchNum(line) {
    if (line.length > 0) props.searchStorage(line);
    else _load();
  }

  return (
    <div id="page" className="home-page">
      {user && (
        <section className="home-info ui-grid">
          <div className="home-info-text">
            <UiPageText title={`${user.type == 3 ? "Мои" : "Новые"} Объекты`} />
          </div>
          <div className="home-info-button">
            <UiButtonColor
              color="indigo"
              text="Новый Объект"
              small={true}
              onClick={() => {
                storeData("edit", null);
                navigate("/storages/add");
              }}
            />
          </div>
        </section>
      )}
      {tableListOrders.length > 0 || props.login.user?.type < 3 ? (
        <section className="page-content">
          <div className="page-search">
            <UiInputSearch
              placeholder="Поиск: Название Объекта"
              onChange={(val) => searchNum(val)}
            />
          </div>
          <UiTableAdaptive
            titleList={clientsTitles}
            grid={clientsTitles.length}
            numOfLines={4}
            scroll={true}
            tableList={tableListOrders}
          />
        </section>
      ) : (
        <div className="home-page-empty">
          <SvgIsland className="home-page-empty-icon" />
          <h3>Объекты не добавлены</h3>
          <p>
            Чтобы увидеть список объектов, добавьте их при помощи кнопки "Новый
            Объект"
          </p>
          <div className="home-page-empty-button">
            <UiButtonColor
              color="indigo"
              text="Новый объект"
              small={true}
              onClick={() => {
                storeData("edit", null);
                navigate("/storages/add");
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default connect(
  ({ order, login, comment, logs, user, storage, category, settings }) => ({
    order,
    login,
    comment,
    logs,
    user,
    settings,
    storage,
    category,
  }),
  {
    changeConfirmState: changeConfirmStateAction,
    setLoaderState: setLoaderStateAction,
    getStorageListByIdArray: getStorageListByIdArrayAction,
    getStorageList: getStorageListAction,
    searchStorage: searchStorageAction,

    update: updateAction,
  }
)(HomePage);
