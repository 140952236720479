import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  getSettingList as getSettingListAction,
  getSettingValue as getSettingValueAction,
  updateByKey as updateByKeyAction,
  getIntervalList as getIntervalListAction,
  updateInterval as updateIntervalAction,
  addInterval as addIntervalAction,
} from "../../redux/modules/settings";

import UiPageText from "../../components/text/UiPageText";
import UiInputText from "../../components/inputs/UiInputText";

import UiSwitch from "../../components/switch/UiSwitch";

import { formatDateYMD, formatDatePlusDayYMD } from "../../utilities/date";
import { addFile } from "../../services/Storage";

import "./SettingStyle.scss";

function SettingPage(props) {
  const intervalTitles = [
    { sort: true, title: "Интервал" },
    { sort: false, title: "Дата" },
    { sort: false, title: "Лимит заказов" },
    { sort: false, title: "Управление" },
  ];

  const [intervalList, setIntervalList] = useState([]);
  const [date, setDate] = useState(new Date());

  const [intervalTime, setIntervalTime] = useState("");
  const [intervalLimit, setIntervalLimit] = useState(0);

  useEffect(() => {
    if (props.login.user) props.getSettingList();
    props.getIntervalList(formatDateYMD(new Date(date), "-"));
  }, [props.login.user]);

  useEffect(() => {
    var arr = [];
    props.settings.listInterval.map((item, index) => {
      arr.push([
        {
          title: intervalTitles[0].title,
          text: item.title,
        },
        {
          title: intervalTitles[1].title,
          text: item.date,
        },
        {
          title: intervalTitles[2].title,
          text: item.order_limit,
        },
        {
          button: "delete",
          onClick: () => {
            if (window.confirm("Удалить элемент ?")) {
              let _item = item;
              _item.is_del = item.is_del == 1 ? 0 : 1;
              props
                .updateInterval(_item)
                .then(() =>
                  props.getIntervalList(formatDateYMD(new Date(date), "-"))
                );
            }
          },
        },
      ]);
    });
    setIntervalList(arr);
  }, [props.settings.listInterval]);

  function upload(file) {
    addFile(file.target.files[0], 0).then((res) => {
      if (res.success) {
        props.updateByKey("home_video_file", res.response);
      } else {
        alert("Файл не является видео");
      }
    });
  }

  let list = props.settings.list
    .filter((it) => {
      return it.show_in_list == 0;
    })
    .map((item, index) => {
      if (item.is_switch) {
        return (
          <UiSwitch
            key={index}
            label={`${item.description}`}
            value={item.value == 1 ? false : true}
            onChange={(val) => props.updateByKey(item.title, val)}
          />
        );
      }
      if (item.is_file) {
        return (
          <div key={index}>
            <label for="file">{`${item.description} ${item.value}`}</label>
            <input type="file" onChange={upload} />
          </div>
        );
      } else {
        return (
          <UiInputText
            key={index}
            placeholder={item.value}
            label={`${item.description}`}
            onBlur={(val) => {
              props.updateByKey(item.title, val);
            }}
          />
        );
      }
    });

  return (
    <div id="page" className="home-page">
      <section className="home-info ui-grid">
        <div className="home-info-text">
          <UiPageText title="Настройки" />
        </div>
      </section>
    </div>
  );
}

export default connect(({ login, settings }) => ({ login, settings }), {
  getSettingList: getSettingListAction,
  getSettingValue: getSettingValueAction,
  updateByKey: updateByKeyAction,
  getIntervalList: getIntervalListAction,
  updateInterval: updateIntervalAction,
  addInterval: addIntervalAction,
})(SettingPage);
