import React from "react";
import { useNavigate } from "react-router-dom";
import EnvConstant from "../../constants/EnvConstant";

import "./UiLogoTitle.scss";

function UiLogoTitle({ link, size, text }) {
  const navigate = useNavigate();

  return (
    <h2
      className={size}
      onClick={link ? () => navigate("/", { replace: true }) : null}
    >
      {text ? text : EnvConstant.APP_NAME}
      <br />
      {text ? null : <span>CRM</span> }
    </h2>
  );
}

export default UiLogoTitle;
