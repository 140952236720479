import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import {
  getAllPromoList as getAllPromoListAction,
  editSpecialPromo as editSpecialPromoAction,
} from "../../redux/modules/promo";

import { convertImages, fromArrayListKey } from "../../utilities/array";
import { formatDateDMY } from "../../utilities/date";

import UiInputSearch from "../../components/inputs/UiInputSearch";
import UiModalEditComposition from "../../components/modals/UiModalEditComposition";
import UiPageText from "../../components/text/UiPageText";
import UiTableAdaptive from "../../components/table/UiTableAdaptive";

import StorageConstant from "../../constants/StorageConstant";
import OrderConstant from "../../constants/OrderConstant";

import Env from "../../services/Env";
import { storeData } from "../../services/Storage";

import "./MaterialsStyle.scss";

function PromoPage(props) {
  const { id } = useParams();
  const navigate = useNavigate();

  const [promoList, setPromoList] = useState([]);
  const [additional, setAdditional] = useState([]);
  const [searchLine, setSearchLine] = useState(null);

  const clientsTitles = [
    { sort: false, title: "" },
    { sort: false, title: "" },
    { sort: true, title: "Объекты" },
    { sort: false, title: "Название" },
    { sort: false, title: "Описание" },
    { sort: false, title: "Дата начала" },
    { sort: false, title: "Дата окончания" },
    { sort: false, title: "Скрыть" },
    { sort: false, title: "Удалить" },
  ];
  const [tableListOrders, setTableListOrders] = useState([]);

  const contactsList = [
    {
      type: 0,
      title: `${StorageConstant.CONTACTS_TYPE_ARRAY[0].text}`,
      text: null,
    },
    {
      type: 1,
      title: `${StorageConstant.CONTACTS_TYPE_ARRAY[1].text}`,
      text: null,
    },
    {
      type: 2,
      title: `${StorageConstant.CONTACTS_TYPE_ARRAY[2].text}`,
      text: null,
    },
    {
      type: 3,
      title: `${StorageConstant.CONTACTS_TYPE_ARRAY[3].text}`,
      text: null,
    },
  ];
  const [order, setOrder] = useState({
    isEdit: false,
    title: "",
    text: "",
    type: 0,
    category: 1,
    sub_category: 0,
    price: 0,
    tags: "",
    sale: 0,
    sale_count: 0,
    user_id: 0,
    additional: [],
    images: [],
    contact: contactsList,
  });
  const [state, setState] = useState({
    isEditOpen: false,
    isLoad: false,
    isDialogCrop: false,
    isDialogCrop2: false,
    selectedCustomItem: null,
    customId: null,
    customIndex: null,
    addPhoto: null,
    addTitle: "S",
    addText: "",
    addPhotos: [],
    savedImages: [],
    removeArray: [],
    imagesList: [],
  });

  useEffect(() => {
    loadPromo();
  }, []);

  useEffect(() => {
    let arr = [];
    promoList.map((item) => {
      let images = convertImages(item.image);
      item.images = images;
      arr.push([
        {
          button: "edit",
          onClick: () => {
            /*storeData("edit", item);
            navigate("/material/add");*/

            setState({
              ...state,
              isEditOpen: true,
              selectedCustomItem: item,
              customId: item.id ? item.id : null,
              //customIndex: itemIndex,
            });
          },
        },
        { image: images[0] ? Env.PUBLIC_URL + images[0] : null },
        {
          text: item.storage_title,
          linkClick: () => {
            setState({
              ...state,
              isEditOpen: true,
              selectedCustomItem: item,
              customId: item.id ? item.id : null,
              //customIndex: itemIndex,
            });
          },
        },
        {
          text: item.title,
        },
        { text: item.text },
        { text: formatDateDMY( item.start_date ) },
        { text: formatDateDMY( item.end_date ) },
        {
          button: item.is_active == 1 ? "eye-open" : "eye",
          color: item.is_active == 1 ? "gray" : "red",
          onClick: () => {
            let _item = item;
            _item.is_active = item.is_active == 1 ? 0 : 1;
            console.log(_item);
            props.editSpecialPromo(_item).then(() => loadPromo());
          },
        },
        {
          button: "delete",
          color: "blue",
          onClick: () => {
            let _item = item;
            _item.is_del = 1;
            props.editSpecialPromo(_item).then(() => loadPromo());
          },
        },
      ]);
    });
    setTableListOrders(arr);
  }, [promoList]);

  function loadPromo() {
    props.getAllPromoList().then((data) => {
      setPromoList(data);
    });
  }

  return (
    <div id="page" className="home-page">
      <section className="home-info ui-grid">
        <div className="home-info-text">
          <UiPageText
            title="Список Aкций"
            text={["Уровень доступа: " + "администратор (все акции)"]}
          />
        </div>
        <div className="home-info-button"></div>
      </section>
      <section className="page-content">
        <div className="page-search">
          <UiInputSearch
            //label="Поиск Акции"
            placeholder="Поиск:"
            onChange={(val) => setSearchLine(val)}
          />
        </div>

        <UiTableAdaptive
          titleList={clientsTitles}
          grid={clientsTitles.length}
          tableList={tableListOrders}
          scroll={true}
          search={searchLine}
        />
      </section>

      <UiModalEditComposition
        modalOpen={state.isEditOpen}
        item={state.selectedCustomItem}
        onSave={(val) => {
          setState({ ...state, isEditOpen: false });
          if (state.customId)
            props.editSpecialPromo(val).then(() => {
              loadPromo();
            });
          else {
            let _arr = additional;
            additional[state.customIndex] = val;
            setState({ ...state, additional: _arr, isLoad: false });
          }
        }}
        onChangeImage={(arr) => {
          setState({
            ...state,
            savedImages: arr,
            isEditOpen: false,
            isDialogImageEdit: true,
            isDialogCrop2: true,
          });
        }}
        cancelClick={() => setState({ ...state, isEditOpen: false })}
      />
    </div>
  );
}

export default connect(
  ({ material, promo }) => ({
    material,
    promo,
  }),
  {
    getAllPromoList: getAllPromoListAction,
    editSpecialPromo: editSpecialPromoAction,
  }
)(PromoPage);
