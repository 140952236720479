import React, { useRef, useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import ru from "date-fns/locale/ru";

import "react-datepicker/dist/react-datepicker.css";

import "./UiSelectDates.scss";

function UiSelectDates(props) {
  const [startDate, setStartDate] = useState(new Date());

  useEffect(() => {
    registerLocale("ru", ru);
  }, []);

  useEffect(() => {
    if (props.date) setStartDate(props.date);
  }, [props.date]);

  return (
    <div className="select-dates-wrap">
      {props.label ? <label>{props.label}</label> : null}
      <DatePicker
        locale="ru"
        selected={startDate}
        dateFormat="dd-MM-yyyy"
        onChange={(date) => {
          setStartDate(date);
          props.onChange(date);
        }}
      />
    </div>
  );
}

export default UiSelectDates;
