import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

import UiButtonColor from "../../components/button/UiButtonColor";
import UiInputText from "../../components/inputs/UiInputText";
import UiHeaderBg from "../../components/header/UiHeaderBg";
import UiText from "../../components/text/UiText";
import UiTextTitle from "../../components/text/UiTextTitle";
import UiTextInputMasked from "../../components/inputs/UiTextInputMasked";

import { editWorker as editWorkerAction } from "../../redux/modules/users";
import { logoutUser as logoutUserAction } from "../../redux/modules/login";
import { ValidateInput } from "../../utilities/validate";

import "./UserStyle.scss";

function UserPage(props) {
  const navigate = useNavigate();
  const [keyboardActive, setKeyboardActive] = useState(false);
  const [email, setEmail] = useState(null);
  const [pass, setPass] = useState(null);
  const [user, setUser] = useState(null);

  useEffect(() => {
    if (props.login.user) {
      console.log(props.login.user);
      setUser(props.login.user);
    }
  }, [props.login.user]);

  function updateUserObj(_key, _val) {
    if (_key != "email") {
    }
    let _user = user;
    _user[_key] = _val;
    setUser(_user);
  }

  function update() {
    props.editWorker(user).then((res) => {});
  }

  return (
    <div id="page" className="login-page">
      <div className="form">
        {user && (
          <div className="form-inner">
            <div className="login-title-wrap">
              <UiTextTitle
                adaptive
                color="brand"
                size={32}
                text="Редактирование профиля"
              />
              <UiText adaptive color="dark" size="f22"></UiText>
            </div>

            <div className="login-input-wrap">
              <UiInputText
                onBlur={() => setKeyboardActive(false)}
                onChange={(val) => updateUserObj("email", val)}
                onFocus={() => setKeyboardActive(true)}
                placeholder="Email"
                type="text"
                name="email"
                error={
                  user.email != null && !ValidateInput("email", user.email)
                }
                errorText={"Не корректный email"}
                value={user.email}
              />
            </div>
            <div className="login-input-wrap">
              <UiInputText
                onBlur={() => setKeyboardActive(false)}
                onChange={(val) => updateUserObj("password", val)}
                onFocus={() => setKeyboardActive(true)}
                placeholder="Пароль"
                type="password"
                name="password"
                value={user.password}
              />
            </div>

            <br />

            <div className="login-input-wrap">
              <UiText color="dark">Дополнительная информация:</UiText>

              <UiTextInputMasked
                placeholder="Введите Телефон.."
                onChange={(val) => updateUserObj("phone", val)}
                value={user.phone}
              />
            </div>
            <div className="login-input-wrap">
              <UiInputText
                onBlur={() => setKeyboardActive(false)}
                onChange={(val) => updateUserObj("name", val)}
                onFocus={() => setKeyboardActive(true)}
                placeholder="Имя пользователя"
                type="text"
                name="username"
                value={user.name}
              />
            </div>
            <div className="login-input-wrap">
              <UiInputText
                onBlur={() => setKeyboardActive(false)}
                onChange={(val) => updateUserObj("org_name", val)}
                onFocus={() => setKeyboardActive(true)}
                placeholder="Название объекта"
                type="text"
                name="username"
                value={user.org_name}
              />
            </div>

            <div className="reg-button-wrap">
              <UiButtonColor
                color="brand"
                error={email == "" || pass == ""}
                errorText=""
                onClick={() => update()}
                text="Сохранить профиль"
              />
            </div>

            <div className="reg-button-wrap">
              <UiButtonColor
                color="accent"
                errorText=""
                onClick={() => {
                  props.logoutUser();
                  navigate("../login", { replace: true });
                }}
                text="Выход из профиля"
              />
            </div>
          </div>
        )}
      </div>
      <UiHeaderBg
        bg="profile"
        text={"Мой Профиль"}
        keyboardActive={keyboardActive}
      />
    </div>
  );
}

export default connect(({ login, users }) => ({ login, users }), {
  editWorker: editWorkerAction,
  logoutUser: logoutUserAction,
})(UserPage);
