import React, { useEffect, useState } from "react";

import UiButtonColor from "../button/UiButtonColor";
import UiInputText from "../inputs/UiInputText";
import UiTextArea from "../inputs/UiTextArea";
import UiInputTimeRange from "../inputs/UiInputTimeRange";
import UiDropDown from "../inputs/UiDropDown";

import UiSelectDates from "../select/UiSelectDates";
import UiModal from "./UiModal";

import { formatDateYMD } from "../../utilities/date";
import { addFileBase64, convertImages } from "../../services/Storage";

import OrderConstant from "../../constants/OrderConstant";

import "./UiModalNotification.scss";
import Env from "../../services/Env";

function UiModalEditComposition(props) {
  const [activeOption, setOption] = useState(null);
  const defaultValue = {
    title: null,
    text: null,
    start_date: new Date(),
    end_date: new Date(),
    time: null,
    image: null,
    time_offset: null,
  };

  const [item, setItem] = useState(defaultValue);

  useEffect(() => {
    console.log(props.item);
    if (props.item) {
      setItem(props.item);
    } else {
      setItem(defaultValue);
      var elements = document.getElementsByTagName("input");
      for (var ii = 0; ii < elements.length; ii++) {
        if (elements[ii].type == "text") {
          elements[ii].value = "";
        }
      }
      var elements = document.getElementsByTagName("textarea");
      for (var ii = 0; ii < elements.length; ii++) {
        if (elements[ii].type == "text") {
          elements[ii].value = "";
        }
      }
    }
  }, [props.modalOpen, props.item]);

  function getBase64(file, cb) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  }

  return (
    <UiModal name="modal-notification" show={props.modalOpen}>
      <div className="modal-container">
        <h3>Информация об Акции (добавить / редактировать)</h3>
        <button className="modal-close" onClick={props.cancelClick}>
          Х
        </button>
        <div className="modal-add-form">
          <UiInputText
            label="Название"
            value={item.title}
            small
            onChange={(val) => setItem({ ...item, title: val })}
          />

          <UiTextArea
            label="Описание"
            value={item.text}
            small
            onChange={(val) => setItem({ ...item, text: val })}
          />

          <div className="modal-action-date-time">
            <div className="modal-action-date">
              <UiSelectDates
                label="Дата начала"
                date={new Date(item.start_date)}
                onChange={(val) =>
                  setItem({
                    ...item,
                    start_date: formatDateYMD(new Date(val), "-"),
                  })
                }
              />
              <UiSelectDates
                label="Дата окончания"
                date={new Date(item.end_date)}
                onChange={(val) =>
                  setItem({
                    ...item,
                    end_date: formatDateYMD(new Date(val), "-"),
                  })
                }
              />
            </div>
            <div className="modal-action-time">
              <UiInputTimeRange
                value={item.time}
                onChange={(time) => {
                  console.log(time);
                  setItem({
                    ...item,
                    time: time,
                  });
                }}
              />
            </div>
          </div>

          <UiDropDown
            label="Часовой пояс"
            value={item.time_offset}
            items={ OrderConstant.TIME_OFFSET }
            onChange={(val) =>   setItem({ ...item, time_offset: val }) }
          />

          {item.image && (
            <img
              src={Env.PUBLIC_URL + item.image}
              style={{ width: "100px", height: "100px", borderRadius: "4px" }}
            />
          )}
          <UiInputText
            label="Фотография"
            type="file"
            onChange={(e) => {
              getBase64(e.target.files[e.target.files.length - 1], (result) => {
                addFileBase64(result, 0).then((res) => {
                  if (res.response) setItem({ ...item, image: res.response });
                });
              });
            }}
          />

          <UiButtonColor
            text="Сохранить"
            onClick={() => {
              if (item.title == "" || item.title == null) {
                alert("Укажите название акции");
              } else if (item.text == "" || item.text == null) {
                alert("Укажите описание акции");
              } else if (item.time == "" || item.time == null) {
                alert("Укажите время действия акции");
              } else { 
                props.onSave(item);
              }
            }}
          />
        </div>
      </div>
    </UiModal>
  );
}

export default UiModalEditComposition;
