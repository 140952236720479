 
import { 
    getOrderPromo, addOrderSpecialPromo,
    editOrderSpecialPromo,
    destroyOrderSpecialPromo, 
    getAllPromo
} from "../../services/Promo";
 
const moduleName = "promo"; 
const SET_PROMO = `${moduleName}/SET_PROMO`;
 

const SET_API_REQUEST = `${moduleName}/SET_API_REQUEST`;

const REGISTER = `${moduleName}/REGISTER`;

const defaultState = {
 
  
    promoOrder: [],
    addressList: [],

    statistics: null,
    isFetching: false,
};

export default (state = defaultState, { type, payload }) => {
    switch (type) {
    
        case SET_PROMO:
            return { ...state, promoOrder: payload };
      
        case SET_API_REQUEST:
            return { ...state, isFetching: payload };

        default:
            return state;
    }
};

 
export const setLoaderState = (val) => async (dispatch) => {
    try {
        dispatch({
            type: SET_API_REQUEST,
            payload: val
        });
    } catch (error) { 
    }
}

 

export const destroySpecialPromo = (_id) => async (dispatch, getState) => {
    try {
        let _data = { id: _id };
        _data['api_token'] = getState().login.user.api_token;
        return destroyOrderSpecialPromo(_data).then((res) => {
            dispatch({
                type: SET_API_REQUEST,
                payload: false
            });

            return res;
        })
    } catch (error) {
        console.error(error);
    }
}

export const addSpecialPromo = (_data) => async (dispatch, getState) => {
    try {
            _data['api_token'] = getState().login.user.api_token;
            return addOrderSpecialPromo(_data).then((res) => {
                dispatch({
                    type: SET_API_REQUEST,
                    payload: false
                });
                if (res.status == 200) {
                   if(res.response.order_id) dispatch(getPromo(res.response.order_id))
                }
                return res;
            })
    } catch (error) {
        console.error(error);
    }
}

export const editSpecialPromo = (_data) => async (dispatch, getState) => {
    try {
            _data['api_token'] = getState().login.user.api_token;
            return editOrderSpecialPromo(_data).then((res) => {
                dispatch({
                    type: SET_API_REQUEST,
                    payload: false
                });
                if (res.status == 200) {
                   if(res.response.order_id) dispatch(getPromo(res.response.order_id))
                }
                return res;
            })
    } catch (error) {
        console.error(error);
    }
}


export const getAllPromoList = () => async (dispatch, getState) => {
    try {
  
        return getAllPromo().then((res) => {
            dispatch({
                type: SET_API_REQUEST,
                payload: false
            });
            if (res.status == 200) {
                dispatch({
                    type: SET_PROMO,
                    payload: res.response
                });
                return res.response;
            } else {
                dispatch({
                    type: SET_PROMO,
                    payload: []
                });
                return [];
            }
           
        })
    } catch (error) {
        console.error(error);
    }

}

export const getPromo = (_id) => async (dispatch, getState) => {
    try {
        let _data = { id: _id };

        return getOrderPromo(_data).then((res) => {
            dispatch({
                type: SET_API_REQUEST,
                payload: false
            });
            if (res.status == 200) {
                dispatch({
                    type: SET_PROMO,
                    payload: res.response
                });
            } else {
                dispatch({
                    type: SET_PROMO,
                    payload: []
                });
            }
            return res;
        })
    } catch (error) {
        console.error(error);
    }

}


 