import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

import UiButtonColor from "../../components/button/UiButtonColor";
import UiInputText from "../../components/inputs/UiInputText";
import UiTextInputMasked from "../../components/inputs/UiTextInputMasked";
import UiHeaderBg from "../../components/header/UiHeaderBg";
import UiText from "../../components/text/UiText";
import UiTextTitle from "../../components/text/UiTextTitle";

import { registerUser as registerUserAction } from "../../redux/modules/login";
import { ValidateInput } from "../../utilities/validate";

import "./RegStyle.scss";
import UiSelect from "../../components/select/UiSelect";

function RegPage(props) {
  const navigate = useNavigate();
  const [keyboardActive, setKeyboardActive] = useState(false);
  const [email, setEmail] = useState(null);
  const [pass, setPass] = useState(null);
  const [pass2, setPass2] = useState(null);
  const [phone, setPhone] = useState("");
  const [name, setName] = useState("");
  const [org, setOrg] = useState("");

  const [passErr, setPassErr] = useState(false);
  const [passErrTxt, setPassErrTxt] = useState("Пароли не совпадают");

  useEffect(() => {
    if (props.login.user) {
      navigate("../", { replace: true });
    }
  }, [props.login.user]);

  useEffect(() => {
    if (pass != null) {
      if (pass != pass2) {
        setPassErr(true);
        setPassErrTxt("Пароли не совпадают");
      } else if (pass.length < 6) {
        setPassErr(true);
        setPassErrTxt("Пароль короче 6 символов");
      } else {
        setPassErr(false);
      }
    }
  }, [pass, pass2]);

  function reg() {
    if (email && pass && !passErr && ValidateInput("email", email)) {
       props.registerUser({
        email: email,
        password: pass,
        name: name,
        org_name: org,
        phone: phone,
        type: 3
       })
    }
  }

  return (
    <div id="page" className="login-page">
      <div className="form">
        <div className="form-inner">
          <div className="login-title-wrap">
            <UiTextTitle adaptive color="brand" size={32} text="Регистрация" />
            <UiText adaptive color="dark" size="f22">
              Создание профиля в системе
            </UiText>
          </div>
          <div className="login-input-wrap">
            <UiInputText
              onBlur={() => setKeyboardActive(false)}
              onChange={(val) => setEmail(val)}
              onFocus={() => setKeyboardActive(true)}
              placeholder="Email"
              type="text"
              name="username"
              error={email != null && !ValidateInput("email", email)}
              errorText={"Не корректный email"}
            />
          </div>
          <div className="login-input-wrap">
            <UiInputText
              onBlur={() => setKeyboardActive(false)}
              onChange={(val) => setPass(val)}
              onFocus={() => setKeyboardActive(true)}
              placeholder="Пароль"
              type="password"
              name="password"
              error={passErr}
              errorText={""}
            />
          </div>
          <div className="login-input-wrap">
            <UiInputText
              onBlur={() => setKeyboardActive(false)}
              onChange={(val) => setPass2(val)}
              onFocus={() => setKeyboardActive(true)}
              placeholder="Подтверждение пароля"
              type="password"
              name="password"
              error={passErr}
              errorText={passErrTxt}
            />
          </div>
          <br />

          <div className="login-input-wrap">
            <UiText color="dark">Дополнительная информация:</UiText>
    
            <UiTextInputMasked 
              placeholder="Введите Телефон.."
              onChange={(val) => {
                setPhone(val);
              }}
            />
          </div>
          <div className="login-input-wrap">
            <UiInputText
              onBlur={() => setKeyboardActive(false)}
              onChange={(val) => setName(val)}
              onFocus={() => setKeyboardActive(true)}
              placeholder="Имя пользователя"
              type="text"
              name="username"
            />
          </div>
          <div className="login-input-wrap">
            <UiInputText
              onBlur={() => setKeyboardActive(false)}
              onChange={(val) => setOrg(val)}
              onFocus={() => setKeyboardActive(true)}
              placeholder="Название объекта"
              type="text"
              name="username"
            />
          </div>

          <div className="login-button-wrap">
            <UiButtonColor
              color="brand"
              error={true}
              errorText="Заполните необходимые поля"
              onClick={() => reg()}
              text="Зарегистрироваться"
            />
          </div>

          <div className="reg-button-wrap">
            <UiText color="dark">Войти в свой аккаунт</UiText>
            <UiButtonColor
              color="dark"
              error={email == "" || pass == ""}
              errorText="Заполните необходимые поля"
              onClick={() => navigate("/login")}
              text="Вход"
            />
          </div>
        </div>
      </div>
      <UiHeaderBg bg="login" keyboardActive={keyboardActive} />
    </div>
  );
}

export default connect(({ login }) => ({ login }), {
  registerUser: registerUserAction,
})(RegPage);
