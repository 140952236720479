import React, { useEffect, useState } from "react";

import "./UiMap.scss";

const UiMapAddress = (props) => {
  const json = {
    type: "FeatureCollection",
    metadata: { name: "mate", creator: "Yandex Map Constructor" },
    features: [
      {
        type: "Feature",
        id: 0,
        geometry: {
          type: "Polygon",
          coordinates: [],
        },
        properties: {
          fill: "#ed4543",
          "fill-opacity": 0,
          stroke: "#ed4543",
          "stroke-width": "2",
          "stroke-opacity": 0.5,
        },
      },
    ],
  };

  const [deliveryZones, setDeliveryZones] = useState([]);
  const [deliveryPoint, setDeliveryPoint] = useState(null);
  const [mainMap, stMainMap] = useState(null);
  const [mapReady, setMapReady] = useState(false);

  useEffect(() => {
    window.ymaps.ready(() => {
      var placemark = null;
      var myMap = new window.ymaps.Map("mapAddress", {
          center: [37.6177, 55.755863],
          controls: [],
          zoom: 13,
        }),
        deliveryPoint = new window.ymaps.GeoObject(
          {
            geometry: { type: "Point" },
          },
          {
            preset: "islands#blackDotIcon",
            draggable: true,
            iconCaptionMaxWidth: "215",
          }
        );

      deliveryPoint.events.add("dragend", (e) => {
        var target = e.get("target");
        target.properties.set(
          "balloonContentBody",
          target.geometry.getCoordinates()
        );
        myMap.geoObjects.add(placemark);

        window.ymaps
          .geocode(placemark.geometry.getCoordinates(), {
            results: 1,
          })
          .then((res) => {
            var newContent = res.geoObjects.get(0)
              ? res.geoObjects.get(0).properties.get("text")
              : "Не удалось определить адрес.";
            props.onChangeAddress(newContent);
            props.onChange(placemark.geometry.getCoordinates());

            highlightResult(
              deliveryZones,
              deliveryPoint,
              res.geoObjects.get(0)
            );
          });
      });

      myMap.geoObjects.add(deliveryPoint);
      setDeliveryPoint(deliveryPoint);

      stMainMap(myMap);

      myMap.events.add("click", (e) => {
        var position = e.get("coords");
        deliveryPoint.geometry.setCoordinates(position);
        window.ymaps
          .geocode(position, {
            results: 1,
          })
          .then((res) => {
            var newContent = res.geoObjects.get(0)
              ? res.geoObjects.get(0).properties.get("text")
              : "Не удалось определить адрес.";
            props.onChangeAddress(newContent);
            props.onChange(position);

            highlightResult(
              deliveryZones,
              deliveryPoint,
              res.geoObjects.get(0)
            );
          });
      });

      // Сравним положение, вычисленное по ip пользователя и
      // положение, вычисленное средствами браузера.
      window.ymaps.geolocation
        .get({
          provider: "browser",
          mapStateAutoApply: true,
        })
        .then((result) => {
          let firstGeoObject = result.geoObjects.get(0),
            coords = firstGeoObject.geometry.getCoordinates();
          if (props.address) {
            var geocoder = new window.ymaps.geocode(props.address).then(
              (res) => {
                var firstGeoObject = res.geoObjects.get(0),
                  coords = firstGeoObject.geometry.getCoordinates();
                deliveryPoint.geometry.setCoordinates(coords);
                myMap.setCenter(coords);
                stMainMap(myMap);
              }
            );
          }
        });

      if (document.getElementById("suggest-input")) {
        var suggestView = new window.ymaps.SuggestView("suggest-input", {
          provider: {
            suggest: function (request, options) {
              return window.ymaps.suggest("Сочи , " + request);
            },
          },
        });

        suggestView.events.add("select", (e) => {
          var addr = e.originalEvent.item.value;
          props.onChangeAddress(addr);
        });

        document.getElementById("suggest-input").onblur = () => {
          //console.log("document.getElementById ", document.getElementById("suggest").value );
        };
      }

      // Добавляем зоны на карту.
      var _deliveryZones = window.ymaps.geoQuery(json).addToMap(myMap);

      // Задаём цвет и контент балунов полигонов.
      _deliveryZones.each(function (obj) {
        obj.events.add("click", (e) => {
          var position = e.get("coords");
          deliveryPoint.geometry.setCoordinates(position);

          /*
          window.ymaps
          .geocode(position, {
            results: 1,
          })
          .then((res) => {
            var newContent = res.geoObjects.get(0)
              ? res.geoObjects.get(0).properties.get("text")
              : "Не удалось определить адрес.";
            props.onChangeAddress(newContent);
            highlightResult(
              deliveryZones,
              deliveryPoint,
              res.geoObjects.get(0)
            );
          });
          */
        });

        /*
        obj.options.set({
          fillColor: obj.properties.get("fill"),
          fillOpacity: 0.01,
          strokeColor: obj.properties.get("stroke"),
          strokeWidth: obj.properties.get("stroke-width"),
          strokeOpacity: 0.1,
        });
        obj.properties.set("balloonContent", obj.properties.get("description"));
        */
      });

      setDeliveryZones(_deliveryZones);

      // При перемещении метки сбрасываем подпись, содержимое балуна и перекрашиваем метку.
      deliveryPoint.events.add("dragstart", function () {
        deliveryPoint.properties.set({ iconCaption: "", balloonContent: "" });
        deliveryPoint.options.set("iconColor", "black");
      });

      // По окончании перемещения метки вызываем функцию выделения зоны доставки.
      deliveryPoint.events.add("dragend", () => {
        console.log(deliveryZones, deliveryPoint, deliveryPoint);
        highlightResult(deliveryZones, deliveryPoint, deliveryPoint);
      });

      setMapReady(true);
    });
  }, []);

  useEffect(() => {
    if (props.address && mapReady) {
      window.ymaps.ready(() => {
        var geocoder = new window.ymaps.geocode(props.address).then((res) => {
          var firstGeoObject = res.geoObjects.get(0),
            coords = firstGeoObject.geometry.getCoordinates();
          deliveryPoint.geometry.setCoordinates(coords);
          mainMap.setCenter(coords);
      
          highlightResult(deliveryZones, deliveryPoint, firstGeoObject, coords);
        });
      });
    }
  }, [props.address, mapReady]);

  function highlightResult(deliveryZones, deliveryPoint, obj, _coords = null) {
    if(_coords){ 
      props.onChange(_coords);
    } else {
      var coords = obj.geometry.getCoordinates(),
      polygon = deliveryZones.searchContaining(coords).get(0); 
      props.onChange(coords);
    }

  }

 
  return <div className="map-address" id="mapAddress"></div>;
};
export default UiMapAddress;
