import Env from "../../services/Env";
import {
    getList, getListByCat, makeStorage, updateStorage,
    getListByArray, getStoragePromo, getCompositionList, searchStorageByTitle
} from "../../services/BaseStorage";
import { safelyParseJSON } from "../../services/Storage";

const moduleName = "storage";

const SET_LIST = `${moduleName}/SET_LIST`;
const SET_COMPOSITION_LIST = `${moduleName}/SET_COMPOSITION_LIST`;
const SET_ALTERNATIVE_LIST = `${moduleName}/SET_ALTERNATIVE_LIST`;
const SET_ERROR = `${moduleName}/SET_ERROR`;
const SET_ERROR_TYPE = `${moduleName}/SET_ERROR_TYPE`;
const SET_API_REQUEST = `${moduleName}/SET_API_REQUEST`;

const defaultState = {
    list: [],
    compositionList: [],
    alternativeList: [],
    error: false,
    isFetching: false,

}

export default (state = defaultState, { type, payload }) => {
    switch (type) {
        case SET_LIST:
            return { ...state, list: payload }
        case SET_ERROR:
            return { ...state, error: payload }
        case SET_COMPOSITION_LIST:
            return { ...state, compositionList: payload }
        case SET_ALTERNATIVE_LIST:
            return { ...state, alternativeList: payload }
        case SET_ERROR_TYPE:
            return { ...state, errorType: payload }

        case SET_API_REQUEST:
            console.log('fetching API request', payload)
            return { ...state, isFetching: payload }

        default:
            return state;
    }

}


export const setList = (value) => async (dispatch) => {
    try {
        dispatch({
            type: SET_LIST,
            payload: value
        });
    } catch (error) {
        console.error(error);
    }
}



export const getStorageList = (_uid="all", _coord = null, _type = null) => async (dispatch, getState) => {
    try {
        dispatch({
            type: SET_API_REQUEST,
            payload: true
        });


        return getList(_uid, _coord, _type).then((res) => {
            console.log(res)
            if (res.response) {
                let arr = res.response
                arr.map((item) => {  item.composition =  safelyParseJSON(item.composition);});
                dispatch({
                    type: SET_LIST,
                    payload: arr
                });
            }
            dispatch({
                type: SET_API_REQUEST,
                payload: false
            });
            return res.response
        })
    } catch (error) {
        console.error(error);
    }

}

export const getStorageListByCat = (_id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: SET_API_REQUEST,
            payload: true
        });


        return getListByCat(_id).then((res) => {
            console.log(res)
            if (res.response) {
                let arr = res.response
                arr.map((item) => {  item.contact =  safelyParseJSON(item.contact);});
                dispatch({
                    type: SET_LIST,
                    payload: arr
                });
            }
            dispatch({
                type: SET_API_REQUEST,
                payload: false
            });
            return res.response
        })
    } catch (error) {
        console.error(error);
    }

}

export const searchStorage = (_title) => async (dispatch, getState) => {
    try {
        dispatch({
            type: SET_API_REQUEST,
            payload: true
        });


        return searchStorageByTitle(_title).then((res) => {
            console.log(res)
            if (res.response) {
                let arr = res.response
               
                dispatch({
                    type: SET_LIST,
                    payload: arr
                });
            }
            dispatch({
                type: SET_API_REQUEST,
                payload: false
            });
            return res.response
        })
    } catch (error) {
        console.error(error);
    }

}




export const getStorageListByIdArray = (_arr) => async (dispatch, getState) => {
    try {
        dispatch({
            type: SET_API_REQUEST,
            payload: true
        });

        let _data = {
            api_token: getState().login.user.api_token,
            id: _arr.split(",")
        }
        return getListByArray(_data).then((res) => {
            if (res.response) {
                dispatch({
                    type: SET_COMPOSITION_LIST,
                    payload: res.response
                });
            }
            dispatch({
                type: SET_API_REQUEST,
                payload: false
            });
            return res.response
        })
    } catch (error) {
        console.error(error);
    }

}


export const addStorage = (_data) => async (dispatch, getState) => {
    try {
        dispatch({
            type: SET_API_REQUEST,
            payload: true
        });

        return makeStorage(getState().login.user.api_token, _data).then((res) => {
            console.log(res)
            if (res.response) {
                dispatch(getStorageListByCat(_data.category));
            }
            dispatch({
                type: SET_API_REQUEST,
                payload: false
            });
            return res
        })
    } catch (error) {
        console.error(error);
    }

}


export const update = (_data) => async (dispatch, getState) => {
    try {
        dispatch({
            type: SET_API_REQUEST,
            payload: true
        });

        return updateStorage(getState().login.user.api_token, _data).then((res) => {
            console.log(res)
            if (res.response) {
                //dispatch(getStorageList());
            }
            dispatch({
                type: SET_API_REQUEST,
                payload: false
            });
            return res
        })
    } catch (error) {
        console.error(error);
    }

}



export const getPromoByStorage = (_id) => async (dispatch, getState) => {
    try {
        let _data = { id: _id };

        return getStoragePromo(_data).then((res) => {
            dispatch({
                type: SET_API_REQUEST,
                payload: false
            });
            if (res.status == 200) {
                dispatch({
                    type: SET_COMPOSITION_LIST,
                    payload: res.response
                });
            } else {
                dispatch({
                    type: SET_COMPOSITION_LIST,
                    payload: []
                });
            }
            return res;
        })
    } catch (error) {
        console.error(error);
    }

}

export const getCompositions = () => async (dispatch, getState) => {
    try {

        return getCompositionList().then((res) => {
            dispatch({
                type: SET_API_REQUEST,
                payload: false
            });
            
            if (res.status == 200) {
                return res.response;
            } else {
                return [];
            }
           
        })
    } catch (error) {
        console.error(error);
    }

}






