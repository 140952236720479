import React from "react";
import { Routes, Route } from "react-router-dom";

import ProtectedRoute from "./routes/ProtectedRoute";
import ProtectedOrgRoute from "./routes/ProtectedOrgRoute";

import LoginPage from "../pages/login/LoginPage";
import RegPage from "../pages/reg/RegPage";
import UserPage from "../pages/user/UserPage";

import HomePage from "../pages/home/HomePage";
import DashboardPage from "../pages/home/DashboardPage";
import ClientsPage from "../pages/clients/ClientsPage";
import ClientPage from "../pages/clients/ClientPage";
import ManagersPage from "../pages/managers/ManagersPage";

import PrintCheckPage from "../pages/home/PrintCheckPage";

import OrgsPage from "../pages/orgs/OrgsPage";

import StoragesPage from "../pages/storages/StoragesPage";
import StoragesAddPage from "../pages/storages/StoragesAddPage";

import StoragesCategoryAddPage from "../pages/categories/StoragesCategoryAddPage";
import StoragesCategoryEditPage from "../pages/categories/StoragesCategoryEditPage";
import StoragesCategorys from "../pages/categories/StoragesCategorys";

import StoragesSubCategoryAddPage from "../pages/categories/StoragesSubCategoryAddPage";
import StoragesSubCategoryEditPage from "../pages/categories/StoragesSubCategoryEditPage";
import StoragesSubCategorys from "../pages/categories/StoragesSubCategorys";

import PromoPage from "../pages/materials/PromoPage";
//import MaterialsPage from "../pages/materials/MaterialsPage";
//import MaterialAddPage from "../pages/materials/MaterialAddPage";

//import ArchivePage from "../pages/archive/ArchivePage";
import SettingPage from "../pages/setting/SettingPage";
//import StatisticPage from "../pages/statistic/StatisticPage";

function AppNavigator() {
  return (
    <Routes>
      <Route path="/login" element={<LoginPage />} />
      <Route
        path="/"
        element={
          <ProtectedRoute>
            <HomePage />
          </ProtectedRoute>
        }
      />
      <Route path="/reg" element={<RegPage />} />
      <Route path="/user" element={<UserPage />} />
      <Route
        path="/dashboard"
        element={
          <ProtectedRoute>
            <DashboardPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/clients"
        element={
          <ProtectedRoute>
            <ProtectedOrgRoute type="0,1,2">
              <ClientsPage />
            </ProtectedOrgRoute>
          </ProtectedRoute>
        }
      />
      <Route
        path="/client"
        element={
          <ProtectedRoute>
            <ProtectedOrgRoute type="0,1,2">
              <ClientPage />
            </ProtectedOrgRoute>
          </ProtectedRoute>
        }
      />
      <Route
        path="/managers"
        element={
          <ProtectedRoute>
            <ProtectedOrgRoute type="0,1">
              <ManagersPage />
            </ProtectedOrgRoute>
          </ProtectedRoute>
        }
      />

      <Route
        path="/orgs"
        element={
          <ProtectedRoute>
            <ProtectedOrgRoute type="0,1">
              <OrgsPage />
            </ProtectedOrgRoute>
          </ProtectedRoute>
        }
      />
      <Route
        path="/storages/category/:id"
        element={
          <ProtectedRoute>
            <ProtectedOrgRoute type="0,1">
              <StoragesPage />
            </ProtectedOrgRoute>
          </ProtectedRoute>
        }
      />

      <Route
        path="/storages/add"
        element={
          <ProtectedRoute>
            <ProtectedOrgRoute type="0,1,2,3">
              <StoragesAddPage />
            </ProtectedOrgRoute>
          </ProtectedRoute>
        }
      />

      <Route
        path="/category/all"
        element={
          <ProtectedRoute>
            <ProtectedOrgRoute type="0,1,2,3">
              <StoragesCategorys />
            </ProtectedOrgRoute>
          </ProtectedRoute>
        }
      />

      <Route
        path="/add/category"
        element={
          <ProtectedRoute>
            <ProtectedOrgRoute type="0,1">
              <StoragesCategoryAddPage />
            </ProtectedOrgRoute>
          </ProtectedRoute>
        }
      />

      <Route
        path="/edit/category/:id"
        element={
          <ProtectedRoute>
            <ProtectedOrgRoute type="0,1">
              <StoragesCategoryEditPage />
            </ProtectedOrgRoute>
          </ProtectedRoute>
        }
      />

      <Route
        path="/subcategory/all"
        element={
          <ProtectedRoute>
            <ProtectedOrgRoute type="0,1,2,3">
              <StoragesSubCategorys />
            </ProtectedOrgRoute>
          </ProtectedRoute>
        }
      />

      <Route
        path="/add/subcategory"
        element={
          <ProtectedRoute>
            <ProtectedOrgRoute type="0,1">
              <StoragesSubCategoryAddPage />
            </ProtectedOrgRoute>
          </ProtectedRoute>
        }
      />

      <Route
        path="/edit/subcategory/:id"
        element={
          <ProtectedRoute>
            <ProtectedOrgRoute type="0,1">
              <StoragesSubCategoryEditPage />
            </ProtectedOrgRoute>
          </ProtectedRoute>
        }
      />

      <Route
        path="/material/list"
        element={
          <ProtectedRoute>
            <ProtectedOrgRoute type="0,1,2,3">
              <PromoPage />
            </ProtectedOrgRoute>
          </ProtectedRoute>
        }
      />

      {/*<Route
        path="/material/add"
        element={
          <ProtectedRoute>
            <ProtectedOrgRoute type="0,1">
              <MaterialAddPage />
            </ProtectedOrgRoute>
          </ProtectedRoute>
        }
      />*/}

      {/*<Route
        path="/material/all"
        element={
          <ProtectedRoute>
            <ProtectedOrgRoute type="0,1,2,3">
              {" "}
              <MaterialsPage />
            </ProtectedOrgRoute>
          </ProtectedRoute>
        }
      />*/}

      {/*<Route
        path="/archive"
        element={
          <ProtectedRoute>
            <ProtectedOrgRoute type="0,1,2,3">
              <ArchivePage />
            </ProtectedOrgRoute>
          </ProtectedRoute>
        }
      />*/}
      <Route
        path="/setting"
        element={
          <ProtectedRoute>
            <ProtectedOrgRoute type="0">
              <SettingPage />
            </ProtectedOrgRoute>
          </ProtectedRoute>
        }
      />
      {/*<Route
        path="/statistic"
        element={
          <ProtectedRoute>
            <ProtectedOrgRoute type="0,1,2,3">
              <StatisticPage />
            </ProtectedOrgRoute>
          </ProtectedRoute>
        }
      />*/}

      <Route
        path="/deal/check/:id"
        element={
          <ProtectedRoute>
            <PrintCheckPage />
          </ProtectedRoute>
        }
      />
    </Routes>
  );
}

export default AppNavigator;
