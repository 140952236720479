import React, { useState, useEffect } from "react";

import "./UiInputText.scss";

const UiTextArea = (props) => {
  const [value, setValue] = useState("");
  useEffect(() => {
    if (typeof props.value !== "undefined") setValue(props.value);
  }, [props.value]);

  return (
    <div className="area-wrap">
      {props.label ? <label>{props.label}</label> : null}

      <textarea
        className="setting-text-area"
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
          props.onChange(e.target.value);
        }}
        onBlur={(e) => { if(props.onBlur) props.onBlur(value) }}
        placeholder={props.placeholder}
      ></textarea>
    </div>
  );
};

export default UiTextArea;
