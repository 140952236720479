export default {
    TAGS_ARRAY: [
      { value: "", text: "" },
      { value: 0, text: "Рекомендуем" },
      { value: 1, text: "Новые" },
      { value: 2, text: "Предложения" },  
    ],

    COMPOSITION_TIPS_ARRAY: [ 
      { value: 0, text: "материал" },
      { value: 1, text: "Композиция" }, 
    ],

    CONTACTS_TYPE_ARRAY: [ 
      { value: 0, text: "Телефон" },
      { value: 1, text: "Сайт" }, 
      { value: 1, text: "Watsapp" }, 
      { value: 1, text: "Telegram" }, 
      { value: 1, text: "Instagram" }, 

    ],

    CATEGORY: [ 
       "Отели",
       "Еда",
       "Развлечения",
    ],

    CONFIRM_STATUS: [ 
      "На рассмотрении" ,
      "Одобрен", 
   ],

   CONFIRM_STATUS_COLORS: [
    "red", "green" 
  ],



}